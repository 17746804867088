a.external-link,
.external-link {
  // padding-left: 0.25rem;
  color: $primary-red;
}

#external-link-modal {
  position: fixed;
  z-index: 11;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
  background: rgba(29, 8, 48, 0.7);
  padding: 0 1rem;
  #modal {
    max-width: 18rem;
    margin: 30vh auto;
    padding: 1.5rem;
    background: $primary-vanilla;
    border-radius: 4px;
    color: $black;
    h3 {
      margin-top: 0.5rem;
      font-weight: 600;
      font-size: 1.125rem;
      line-height: 1.5rem;
      text-align: center;
    }
    p {
      margin-top: 0.75rem;
      font-size: 0.875rem;
      line-height: 1.25rem;
      text-align: center;
      margin-bottom: 1.5rem;
    }
    button {
      border: 1px solid $secondary-gray;
      border-radius: 2px;
      background: transparent;
      color: $secondary-gray;
      font-size: 0.75rem;
      font-weight: bold;
      padding: 0.25rem 1.75rem;
    }
  }
}
