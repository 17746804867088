#bottom-button-wrapper {
  position: fixed;
  bottom: 0px;
  left: 0;
  right: 0;
  margin: 1rem;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  button {
    max-width: 468px; // 500px (current max-width of layout) - 2rem
    height: 48px;
  }
  .transparent {
    background: rgba(255, 255, 255, 0.08);
    border: 1px solid rgba(255, 255, 255, 0.8);
    backdrop-filter: blur(2.71px);
    border-radius: 2px;
  }
  &.non-sticky {
    position: static;
    margin-left: 0;
    margin-right: 0;
  }
}
