#power-section {
  #power-scores {
    background: $black;
    position: relative;
    padding: 5rem 2rem 1rem 1rem;
    margin-bottom: remr(2);
    h2 {
      font-size: remr(3.125);
      font-weight: bold;
      line-height: remr(4);
      margin-bottom: 0;
      &.blue {
        color: $primary-blue;
      }
      &.green {
        color: $secondary-green;
      }
      &.red {
        color: $primary-red;
      }
      svg {
        color: $secondary-gray;
        margin-left: 5px;
        cursor: pointer;
      }
    }
    h3 {
      color: $primary-vanilla;
      font-size: remr(1.125);
      font-weight: semi-bold;
      line-height: remr(1.5);
      margin-bottom: 3vh;
    }
    #share-link {
      cursor: pointer;
      position: absolute;
      bottom: remr(-1.5);
      right: remr(1);
      left: remr(1);
      height: remr(3);
      min-height: pxr(32);
      background: $primary-red;
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding: 0 remr(1);
      border-radius: 2px;
      border: none;
      box-shadow: 0 4px 20px rgba(0, 0, 0, 0.25);
      p.caption {
        color: $primary-vanilla;
        margin-bottom: 0;
        padding-right: 1rem;
        @include x-sm-width {
          font-size: 0.75rem;
        }
      }
      svg {
        color: $primary-vanilla;
      }
    }
  }
  #influence {
    padding: 1rem;
    h3 {
      font-size: remr(1.125);
      line-height: remr(1.5);
      margin-bottom: remr(2);
    }
  }
}

// Bottom Drawer, similar to #welcome-message
#power-message-container {
  position: absolute;
  top: 0;
  width: 100vw;
  min-height: 100vh;
  z-index: 4;
  #power-message {
    div.content {
      img {
        display: block;
        margin: 0 auto 1rem;
        width: 3.75rem;
        height: auto;
      }
      h3 {
        padding-top: 1rem;
        margin-bottom: 0;
      }
      h3,
      h4 {
        color: $primary-red;
        font-weight: 600;
        font-size: 1.125rem;
        line-height: 1.5rem;
        text-align: center;
      }
      h4 {
        color: $black;
      }
      p {
        padding: 0 2.25rem;
        font-size: 0.875rem;
        line-height: 1.25rem;
        text-align: center;
        color: $secondary-gray;
        margin-bottom: 3rem;
      }
    }
    button.btn.btn-primary.btn-block {
      padding: 0.75rem;
      font-size: 0.875rem;
      margin-bottom: 1rem !important;
    }
    button.btn.btn-primary.btn-block.transparent {
      font-size: 0.875rem;
      background: $primary-vanilla;
      color: $secondary-gray;
      border: 1px solid $secondary-gray;
    }
  }
}
