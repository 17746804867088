.layout-wrapper {
  min-height: 100vh;
  // max-width: 500px;
  // margin: 0 auto;
  > div.layout {
    margin: 0 1rem;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
  }
  > div.layout.fullscreen {
    min-height: 100vh;
    margin: 0;
    background-size: cover !important;
    background-position: center !important;
  }
}
