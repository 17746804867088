#voter-info-tabs {
  width: 100%;
  position: sticky;
  position: -webkit-sticky;
  // top: 3.4rem;
  color: $primary-vanilla;
  display: flex;
  justify-content: space-around;
  align-items: center;
  z-index: 2;
  background: $black;

  #early-voting-tab {
    margin-left: 1rem;
    max-width: max-content;
  }

  #voter-info-tab {
    margin-left: 1rem;
    max-width: max-content;
  }

  #state-guide-tab {
    margin-left: 1rem;
    max-width: max-content;
    margin-right: 1rem;
  }

  button {
    margin-top: 3.4rem;
    color: #62c6dd;
    width: 50%;
    border: none;
    padding: 2vh 1vw 0 1vw;
    background: transparent;
    &:active {
      outline: none;
    }
  }

  .active-btn {
    border-bottom: 3px solid;
    border-radius: 1px;

    &:focus {
      outline: none !important;
    }
  }

  p {
    margin-bottom: 0.5rem;
    font-size: 14px;
    font-weight: bold;
    font-style: normal;
    color: $primary-vanilla;
    text-align: center;
    &.muted {
      color: $secondary-gray;
    }
  }
}
