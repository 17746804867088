#user-form {
  .custom-input {
    height: 58px;
    border-bottom: solid 1px $primary-dark-navi;
    margin-top: 10px;
    margin-bottom: 24px;
  }

  .custom-label {
    height: 17px;
    font-family: $main-font;
    font-size: $description-medium;
    color: $black;
    font-style: normal;
    font-weight: bold;
    line-height: 20px;
  }

  .custom-input input {
    background-color: transparent;
    border: none;
    font-size: 14px;
    color: $black;
    line-height: 21px;
    box-shadow: none;
    padding-left: 0;
  }

  .custom-input input:focus {
    outline: none;
  }
  p.tou-notice {
    font-size: 0.75rem;
    font-weight: 400;
    line-height: 1.125rem;
    // text-align: center;
  }
  .custom-checkbox label {
    font-weight: 400;
    font-size: 0.875rem;
    line-height: 1.25rem;
  }
  p {
    font-size: 11px;
    // text-align: center;
    margin-bottom: 1.5rem;
  }
  p.zip-error {
    color: $primary-red;
    font-weight: bold;
    font-size: 1rem;
    text-align: center;
  }
  form small.form-text {
    font-weight: bold;
    color: $primary-red;
  }
  button.btn.btn-primary {
    font-size: 0.875rem;
    height: 48px;
  }
}
