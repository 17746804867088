.btn {
  @extend .mb-1;
  border-radius: 2px;
  font-weight: bold;
  font-size: $description-medium;
  &.rounded {
    border-radius: 4px;
  }
  &.fluid {
    font-size: $main-text;
    width: 100%;
  }
  &.disabled {
    background: $secondary-soft-red;
    border: none;
  }
  &.btn-primary {
    color: $white;
    &:hover {
      background: darken($primary, 2%);
      border: none;
    }
  }
}
