#needs-to-recheck-modal {
  z-index: 5;
  position: fixed;
  top: 0;
  left: 0;
  height: 100vh;
  width: 100vw;
  background: rgba(29, 8, 48, 0.7);
  #bottom-drawer #drawer-content-wrapper {
    height: auto !important;
    min-height: auto !important;
  }
  div.content {
    padding: 1rem;
    .error {
      color: $primary-red;
    }
    h3 {
      padding-top: 1rem;
      font-weight: 600;
      size: 1.125rem;
      text-align: center;
      padding-bottom: 3rem;
      color: black !important;
    }
    p {
      padding: 0 2.25rem;
      font-size: 0.875rem;
      line-height: 1.25rem;
      text-align: center;
      color: $secondary-gray;
      margin-bottom: 3rem;
    }
    button.btn.btn-primary.btn-block {
      padding: 0.75rem;
      font-size: 0.875rem;
      margin-bottom: 1rem !important;
    }
    button.btn.btn-primary.btn-block.transparent {
      font-size: 0.875rem;
      background: $primary-vanilla;
      color: $secondary-gray;
      border: 1px solid $secondary-gray;
    }
  }
}
