#profile-section {
  margin: 4.4rem 0;
  #profile-top {
    background: $primary-dark-navi;
    padding: 1rem 0 2rem;
    text-align: center;
    position: relative;
    img {
      margin: 0 auto;
      height: 7rem;
      width: auto;
      display: block;
      border-radius: 50%;
    }
    h2,
    span {
      margin-top: 0.5rem;
      color: $white;
    }
    span img {
      display: inline-block;
      height: 1rem;
      margin-right: 0.5rem;
    }
    // }
  }
  #voting-information {
    padding: 2rem 1rem 1rem;
    #election-details-link {
      display: flex;
      justify-content: space-between;
      align-items: center;
      margin-bottom: 2rem;
      p.lg {
        font-weight: 600;
        max-width: 66.66%;
        margin-bottom: 0;
      }
      img {
        height: 0.75rem;
        width: auto;
      }
    }
    a {
      text-decoration: none !important;
      &:visited {
        color: $primary-red;
      }
    }
    .action-box {
      border: 1px solid $secondary-gray;
      border-radius: 2px;
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding: 0.75rem;
      p.caption {
        margin-bottom: 0;
      }
      .link {
        font-weight: bold;
        font-size: 0.875rem;
        padding: 0 0.5rem;
      }
      margin: 1rem 0 2rem;
    }
  }
  #terms-and-conditions {
    text-align: center;
    p {
      font-size: 0.75rem;
    }
  }
  #current-version {
    margin: 1rem 1rem 2rem;
    p {
      font-size: 0.75rem;
      text-align: center;
    }
  }
}
