#nudge-warning-wrapper {
  position: fixed;
  top: 0;
  left: 0;
  height: 100vh;
  width: 100vw;
  z-index: 6;
  background: rgba(29, 8, 48, 0.7);
  display: flex;
  justify-content: center;
  align-items: inherit;
  #nudge-warning {
    margin: 8rem 2.875rem auto;
    padding: 2rem 1rem 2rem;
    width: 100%;
    background: $primary-vanilla;
    border-radius: 4px;
    h3 {
      font-size: 1.125rem;
      margin-bottom: 1.5rem;
      font-weight: 600;
      text-align: center !important;
    }
    p {
      font-size: 0.875rem;
      line-height: 1.25rem;
      text-align: center;
      font-weight: 400;
      margin-bottom: 1.5rem;
    }
    #button-row {
      display: flex;
      justify-content: space-between;
      button.btn.transparent {
        background: $primary-vanilla;
        border: 1px solid $secondary-gray;
        color: $secondary-gray;
      }
      button {
        padding: 0.75rem 1.75rem;
        font-size: 0.875rem;
        width: 47%;
      }
      a.btn.btn-primary {
        box-sizing: border-box;
        margin: 0;
        font-family: inherit;
        overflow: visible;
        text-transform: none;
        display: inline-block;
        text-align: center;
        vertical-align: middle;
        user-select: none;
        border: 1px solid transparent;
        line-height: 1.5;
        transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
        background-color: #ff696d;
        border-color: #ff696d;
        margin-bottom: 0.25rem !important;
        border-radius: 2px;
        font-weight: bold;
        color: #ffffff;
        cursor: pointer;
        padding: 0.5rem;
        font-size: 0.825rem;
        padding: 0.75rem 1.75rem;
        padding-top: 0.75rem;
      }
    }
    form {
      display: flex;
      justify-content: center;
      label {
        font-size: 0.875rem;
        padding-top: 0.125rem;
      }
      margin-bottom: 1.5rem;
    }
  }
}
