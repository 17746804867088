#login {
  main {
    margin-top: remr(6.25);
    form {
      width: 100%;
      .custom-input {
        height: 58px;
        border-bottom: solid 1px $primary-dark-navi;
        margin-top: 10px;
        margin-bottom: 28px;
      }

      .custom-label {
        height: 17px;
        font-family: $main-font;
        font-size: $description-medium;
        color: $black;
        font-style: normal;
        font-weight: bold;
        line-height: 20px;
      }

      .custom-input input {
        background-color: transparent;
        border: none;
        font-size: 14px;
        color: $black;
        line-height: 21px;
        box-shadow: none;
        padding-left: 0;
      }

      .custom-input input:focus {
        outline: none;
      }
    }
    img {
      height: 16px;
      width: 16px;
      margin-bottom: 4px;
      margin-right: 0.5rem;
    }
    button.resend-button {
      border: none;
      background: transparent;
      color: $primary-red;
      font-weight: $semi-bold;
      padding: 1rem;
    }
    // Hide number picker
    input[type='number'] {
      -moz-appearance: textfield;
    }
    input[type='number']::-webkit-inner-spin-button,
    input[type='number']::-webkit-outer-spin-button {
      -webkit-appearance: none;
      margin: 0;
    }
  }
  button#join-landslide {
    border: none;
    background: transparent;
    color: $primary-red;
    font-weight: bold;
  }
}
