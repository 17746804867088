#analysis {
  margin: 1.5rem auto;
  max-width: 500px;
  padding: 0 1rem;
  ol {
    padding-left: 1rem;
  }
  h3 {
    font-size: 1rem;
    font-weight: 600;
    line-height: 1.5rem;
  }
  p {
    font-size: 1rem;
    font-weight: 400;
    line-height: 1.5rem;
    b {
      font-weight: bold;
    }
  }
  li {
    b {
      font-weight: bold;
    }
  }
  table {
    width: 100%;
    thead {
      th {
        margin-bottom: 1rem;
        border: 1px solid $secondary-gray;
        padding: 0.25rem;
      }
    }
    tr {
      td {
        border: 1px solid $secondary-gray;
        padding: 0.25rem;
      }
      td:first-of-type {
      }
      td:last-of-type,
      th:last-of-type {
        // text-align: right;
      }
    }
  }
}
