#details-section {
  margin: 1.5rem 1rem 7rem;
  .info-section {
    margin-bottom: 1.5rem;
    p.lg {
      font-size: 1rem;
      line-height: 1.5rem;
      font-weight: 600;
      margin-bottom: 0.25rem;
    }
    p {
      font-size: 1rem;
      line-height: 1.5rem;
    }
    ul {
      padding-left: 1rem;
    }
  }
}
