#more-section {
  margin-bottom: 6rem;
  #top-section {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    z-index: 2;
    background: #1d0830;
    height: 56px;
    button {
      position: absolute;
      top: -0.15rem;
      left: 0.375rem;
      padding: 1rem;
      background: transparent;
      border: none;
      img {
        height: 1.125rem;
        width: auto;
      }
    }
    h1 {
      color: $primary-vanilla;
      // margin-top: -0.35rem;
      font-style: italic;
      font-weight: bold;
      font-size: 1.625rem;
      text-align: center;
    }
    h1.smaller {
      margin-top: -0.25rem;
      color: $primary-vanilla;
      font-weight: 600;
      font-style: normal;
      font-size: 1.125rem;
      text-align: center;
    }
  }
  main {
    margin-top: 7vh;
    padding: 1rem;
    ol {
      padding-left: 1rem;
    }
    ul.options {
      padding-left: 0;
      li,
      a {
        padding: 1rem 0;
        display: flex;
        justify-content: space-between;
        &:hover {
          text-decoration: none;
        }
        p {
          font-weight: 600;
          font-size: 1rem;
        }
        img {
          padding-top: 0.125rem;
          height: 0.75rem;
        }
        &.logout {
          > div {
            width: 100%;
            display: flex;
            justify-content: space-between;
          }
        }
      }
      li.allow-nudge {
        p {
          padding-top: 0.25rem;
        }
        button {
          border: 1px solid $secondary-gray;
          color: $secondary-gray;
          padding: 0.25rem 1rem;
          background: transparent;
          font-size: 0.75rem;
          font-weight: 700;
          width: 4rem;
          &:focus {
            box-shadow: none;
            outline: none;
          }
          &:active {
            box-shadow: none;
            outline: none;
            transform: scale(0.97);
          }
          &.selected {
            box-shadow: none;
            outline: none;
            background: $primary-red;
            color: $primary-vanilla;
            border: 1px solid $primary-red;
          }
          &.yes {
            border-right: 0 solid transparent;
            border-radius: 2px 0 0 2px;
          }
          &.no {
            border-radius: 0 2px 2px 0;
            border-left: 0 solid transparent;
          }
        }
      }
    }
    #privacy-policy-content,
    #tou {
      h1 {
        font-size: 1.2rem;
      }
      ul,
      ol {
        li {
          font-size: 0.875rem;
        }
      }
      table td {
        border: 1px solid black;
        padding: 0.25rem;
        font-size: 0.875rem;
      }
    }
  }
}

#faq {
  // padding: 0 1rem 0 1rem;
  h2 {
    color: $primary-blue;
    font-style: italic;
    font-size: 1.5rem;
    line-height: 1.8rem;
    font-weight: 900;
    text-align: left;
    margin-bottom: 1.5rem;
  }
  button.btn {
    padding-left: 0;
    display: flex;
    margin-bottom: 0;
    padding-bottom: 0;
    p {
      font-size: 1rem;
      font-weight: 600;
      line-height: 1.2rem;
      text-align: left;
      padding-left: 0;
      opacity: 1;
      &:first-of-type {
        width: 1rem !important;
      }
      &:nth-of-type(2) {
        width: 100%;
      }
    }
  }
  p {
    font-weight: 400;
    font-size: 1rem;
    line-height: 1.5rem;
  }
  span {
    font-weight: bold;
  }
}
