// define the primary color
$primary-dark-navi: #1d0830;
$primary-red: #ff696d;
$primary-blue: #62c6dd;
$primary-vanilla: #f7f7f0;
$white: #ffffff;
$black: #1d0830;

$secondary-gray: #6c6e7d;
$secondary-green: #6bc44b;
$secondary-soft-blue: #e1efed;
$secondary-soft-red: #f8e2dc;
$secondary-soft-green: #e1f3db;
$secondary-dark-blue: #0d2463;

$primary: $primary-red;
