#more-details {
  margin: 1rem;
  h1 {
    margin: 2rem 0;
    font-size: 1.5rem;
    line-height: 32px;
    font-weight: 600 !important;
  }
  h2 {
    margin: 4vh 0;
  }
  h3 {
    font-size: 1rem;
  }
  p {
    margin-bottom: 2vh;
  }
  button {
    border-radius: 4px;
    margin-bottom: 4vh !important;
    width: 51vw;
  }
  .state-message {
    color: $secondary-gray;
    font-style: italic;
  }
  .waiting-subtitle {
    padding: 1rem;
    margin: 2rem 0;
    background-color: $secondary-soft-blue;
  }
}
