#action-cards-bar {
  background: $black;
  padding: 3.6rem 0.5rem 0.75rem;
  display: flex;
  overflow-x: scroll;
  overflow-y: hidden;
  .action-card {
    display: inline-block;
    background: $primary-vanilla;
    border-radius: 0.25rem;
    margin: 1rem 0.5rem 1rem;
    padding: 2rem 1rem 1rem;
    > div {
      display: flex;
      flex-direction: column;
      flex-grow: 1;
      height: 100%;
      width: 60vw;
      p.next-step,
      p {
        color: $primary-red;
        font-weight: 600;
        font-size: 1.125rem;
        line-height: 1.375rem;
        margin-bottom: 0rem;
      }
      p {
        color: $black;
        margin-bottom: 1rem;
      }
      .content {
        display: flex;
        flex-direction: column;
        flex-grow: 1;
        justify-content: space-between;
      }
      a {
        text-decoration: none;
      }
      button {
        border: 1px solid $secondary-gray;
        border-radius: 0.25rem;
        color: $secondary-gray;
        display: block;
        font-size: 0.75rem;
        font-weight: bold;
        padding: 5px 20px;
        background: $primary-vanilla;
        &:focus {
          box-shadow: none;
          outline: none;
        }
      }
      .donate-button {
        border: none;
        color: $white;
        background-color: $primary-red;
      }
    }
  }
}
