#crew-deadline {
  .crew-deadline-card {
    border: 1px solid $black;
    border-radius: 2px;
    background: $primary-vanilla;
    padding: 1rem;
    margin-bottom: 1rem;
    display: flex;
    &.active {
      background: $white;
    }
    .timespan {
      h2 {
        width: 20vw;
      }
      margin-right: 1rem;
    }
    .content {
      border-left: 1px solid $secondary-gray;
      padding-left: 1rem;
    }
    align-items: center;
    b {
      font-weight: bold;
      margin-right: 0.25rem;
    }
    h2,
    p {
      margin-bottom: 0;
    }
    h2 {
      padding: 0.25rem 0;
    }
    // iphone 5 fix
    @include sm-width {
      p,
      b {
        font-size: 12px;
      }
      h2 {
        font-size: 1rem;
      }
    }
  }
  .spacer {
    height: 5rem;
  }
}
