#desktop {
  width: 100vw;
  height: 100vh;
  display: flex;
  justify-content: space-between;
  flex-direction: column;
  background-image: linear-gradient(rgba(29, 8, 48, 0.8), rgba(29, 8, 48, 0.8)), url('/static/landslide/images/desktop-background.jpg');
  background-size: cover;
  // background: rgba(100, 100, 100, 0.5);
  #container {
    flex-grow: 1;
    margin: 2rem 16vw;
    @media screen and (max-width: 940px) {
      margin: 2rem 2rem;
    }
    display: flex;
    justify-content: space-between;
    align-items: center;
    #text-content {
      width: 100%;
      padding-right: 8rem;
      @media screen and (max-width: 940px) {
        padding-right: 2rem;
      }
      h1 {
        color: $primary-blue;
        font-style: italic;
      }
      h2 {
        font-size: 1.5rem;
        line-height: 2rem;
        font-weight: bold;
        color: $primary-vanilla;
        margin-bottom: 1.5rem;
        .hashtag {
          color: $primary-red;
        }
      }
      @media screen and (max-height: 840px) {
        h1 {
          font-size: 1.5rem;
        }
        h2 {
          font-size: 1.2rem;
          line-height: 1.5rem;
        }
      }
      #video {
        margin-bottom: 20px;
      }
      p {
        padding-top: 0.75rem;
        font-size: 1.125rem;
        font-weight: $semi-bold;
        color: $primary-vanilla;
        line-height: 1.5rem;
        margin-right: 2rem;
        border-top: 4px solid $primary-blue;
      }
      .error {
        color: $primary-red;
      }
      h5 {
        color: $primary-blue;
        font-weight: bold;
      }
      div.custom-input.form-group {
        margin-top: 3rem;
        display: flex;
        input,
        button {
          width: 50%;
        }
        input {
          margin-right: 1rem;
        }
        button {
          margin-bottom: 0;
          padding: 0.5rem;
        }
      }
    }
    #phone {
      max-width: 100%;
      display: flex;
      justify-content: flex-end;
      img {
        max-height: 70vh;
        @media screen and (max-width: 1200px) {
          max-height: 50vh;
        }
      }
    }
  }
  #bottom-bar {
    background: #11051d;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    padding: 2rem 16vw;
    @media screen and (max-width: 940px) {
      padding: 2rem 3rem;
    }
    p {
      margin-bottom: 0;
    }
    div.right {
      display: flex;
      a {
        // @extend p.description;
        display: block;
        margin-left: 2rem;
      }
    }
  }
}
