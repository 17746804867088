#introduction-v1 {
  min-height: 100vh;
  padding: 10vh 1rem;
  color: $primary-vanilla;
  button#login-button {
    position: absolute;
    top: 4rem;
    color: $primary-vanilla;
    background: transparent;
    display: inline;
    border: none;
    font-size: 0.875rem;
    z-index: 2;
  }
  h1#landslide-logo {
    position: fixed;
    color: $primary-blue;
    display: inline;
    top: 1.5vh;
    left: remr(1);
    font-size: 1.125rem;
    font-style: italic;
    z-index: 2;
  }
  #intro-five {
    margin-top: remr(2);
    h2 {
      color: $primary-vanilla;
      font-weight: bold;
      font-size: remr(2.5);
      line-height: remr(3.125);
      span {
        color: $primary-red;
      }
    }
    h3 {
      margin-top: remr(1);
      color: $primary-vanilla;
      font-weight: 600;
      font-size: remr(1.5);
      line-height: remr(2);
      span {
        color: $primary-red;
      }
      &.spacer {
        padding-top: remr(3);
      }
    }
  }
  #intro-six {
    margin-top: remr(2);
    h2 {
      color: $primary-vanilla;
      font-weight: bold;
      font-size: remr(2.5);
      line-height: remr(3.125);
    }
    h4 {
      margin-top: remr(1);
      color: $primary-vanilla;
      font-weight: 600;
      font-size: remr(1.125);
      line-height: remr(1.5);
    }
    img {
      width: 95vw;
      display: block;
      margin: remr(1) auto;
      margin-left: remr(-0.5);
    }
  }
  #intro-seven {
    h2 {
      color: $primary-vanilla;
      font-weight: bold;
      font-size: remr(2.5);
      line-height: remr(3.125);
    }
    h4 {
      margin-top: remr(1);
      color: $primary-vanilla;
      font-weight: 600;
      font-size: remr(1.125);
      line-height: remr(1.5);
    }
    img {
      width: 90vw;
      display: block;
      margin: remr(1) auto;
      padding-top: remr(4);
    }
  }
  #intro-eight {
    h2 {
      color: $primary-vanilla;
      font-weight: bold;
      font-size: remr(2.5);
      line-height: remr(3.125);
    }
    h4 {
      margin-top: remr(1);
      color: $primary-vanilla;
      font-weight: 600;
      font-size: remr(1.125);
      line-height: remr(1.5);
    }
    img {
      width: 90vw;
      display: block;
      margin: remr(1) auto;
      padding-top: remr(4);
    }
  }

  // Older designs, have been retired at this point
  #intro-one {
    h2 {
      color: $primary-blue;
      font-style: italic;
      font-weight: bold;
      font-size: remr(1.625);
      line-height: remr(1.25);
    }
    h1 {
      margin-top: remr(2);
      color: $primary-vanilla;
      font-size: remr(2.5);
      line-height: remr(3.125);
    }
  }
  #intro-two {
    h1,
    h2 {
      color: $primary-vanilla;
      font-size: remr(1.5);
      line-height: remr(2);
      font-weight: $semi-bold;
      span {
        color: $primary-red;
      }
    }
    h2 {
      margin-top: remr(2);
      margin-bottom: remr(3);
    }
    div.info-box {
      background: rgba(98, 198, 221, 0.2);
      padding: remr(0.8) remr(1.625) remr(0.8) 0;
      margin-bottom: remr(1);
      display: flex;
      align-items: center;
      img {
        height: remr(1.25);
        margin-right: remr(1);
      }
      p {
        color: $primary-vanilla;
        font-size: remr(1.125);
        line-height: remr(1.5);
        font-weight: 300;
        margin-bottom: 0;
        b {
          font-weight: $bold;
          color: $primary-vanilla;
        }
        span {
          font-weight: $bold;
          color: $primary-red;
        }
      }
    }
  }

  #intro-three {
    h2 {
      color: $primary-vanilla;
      font-size: remr(1.5);
      line-height: remr(2);
      font-weight: $semi-bold;
    }
    h3 {
      color: $primary-vanilla;
      font-size: remr(1.125);
      line-height: remr(1.5);
      font-weight: 300;
      margin-bottom: 0;
    }
    img {
      display: block;
      max-width: 100%;
      height: remr(16);
      width: auto;
      margin: remr(2) auto 0;
    }
  }

  #intro-four {
    h1 {
      color: $primary-vanilla;
      font-size: remr(2.5);
      line-height: remr(3.125);
      margin-bottom: remr(2);
      span {
        color: $primary-red;
      }
    }
    div.spacer {
      margin: remr(3);
    }
    // h2 {
    //   color: $primary-vanilla;
    //   font-size: remr(1.5);
    //   line-height: remr(1.5);
    //   &.red {
    //     color: $primary-red;
    //   }
    // }
  }
}
