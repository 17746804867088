#fb-confirm {
  z-index: 3;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.6);
  padding: 1rem;
  #modal {
    margin: 30vh auto;
    padding: 1rem;
    background: $white;
    color: $black;
    p {
      font-size: 1rem;
      line-height: 1.5rem;
    }
    p b {
      font-weight: 500;
    }
    button {
      border: none;
      background: transparent;
      text-transform: uppercase;
      color: $primary-red;
      font-size: 0.875rem;
      font-weight: bold;
      padding: 1rem;
    }
  }
}
