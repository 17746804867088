#power-section #influence #influence-by-state {
  p.lg {
    font-weight: 600;
  }
  ul {
    padding-left: 0;
    li {
      list-style: none;
      font-size: 0.875rem;
      line-height: 1.25rem;
      margin-bottom: 0.25rem;
    }
  }
  margin-bottom: 6rem;
}
