#bottom-nav {
  background: $primary-dark-navi;
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  min-height: 83px;
  display: flex;
  justify-content: space-around;
  align-items: center;
  z-index: 3;
  .btn-wrapper {
    margin: 0 2.5vw;
  }
  button {
    border: none;
    background: $primary-dark-navi;
    min-width: 23.5vw;
    &:active,
    &:focus {
      outline: none;
    }
  }
  img {
    height: 2rem;
    width: auto;
    max-width: 2rem;
    margin: 0 auto;
    display: block;
    margin-bottom: 0.2rem;
  }
  .sml-img {
    height: 1.75rem;
  }
  p {
    font-size: 0.75rem;
    color: $primary-vanilla;
    margin-bottom: 0.5rem;
    text-align: center;
    &.muted {
      color: $secondary-gray;
    }
  }
  button.more-nav {
    img {
      margin-top: -0.25rem;
      height: 1.875rem;
      margin-bottom: 0;
    }
    p {
      margin-top: 0.25rem;
    }
  }
}
.version {
  background: $primary-dark-navi;
  bottom: 0;
  color: $secondary-gray;
  font-size: small;
  left: 0;
  padding-bottom: 2px;
  padding-right: 5px;
  position: fixed;
  right: 0;
  text-align: right;
  z-index: 2;
}
