#dashboard-header {
  background: $primary-dark-navi;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  z-index: 3;
  height: 56px;
  h1 {
    // margin-top: -0.35rem;
    color: $primary-vanilla;
    font-style: italic;
    font-weight: bold;
    font-size: 1.625rem;
    text-align: center;
  }
}

#alt-header {
  background: $primary-dark-navi;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  z-index: 2;
  height: 56px;
  h1 {
    color: $primary-vanilla;
    // margin-top: -0.35rem;
    font-style: italic;
    font-weight: bold;
    font-size: 1.625rem;
    text-align: center;
  }
  .state-title {
    margin-top: -0.25rem;
    color: $primary-vanilla;
    font-weight: 600;
    font-size: 1.125rem;
    text-align: center;
  }
  button {
    position: absolute;
    left: 0.375rem;
    padding: 1rem;
    background: transparent;
    border: none;
    img {
      height: 1.125rem;
      width: auto;
    }
    &.state-title {
      top: -0.15rem;
    }
  }
}
