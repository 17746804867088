$main-font: Poppins, sans-serif;

$landslide-title: 1.625rem; // 26px
$h1-semibold: 2.5rem; // 40px
$h1-small-height: 2rem; // 36px
$h2-semibold: 1.5rem; //24px
$h3-semibold: 1.125rem; // 18px
$mini-title: 1rem; // 16px
$main-text: 0.875rem; // 14px
$main-text-small: 0.75rem; // 12px
$description-medium: 0.75rem; // 12px
$description-medium-small: 0.625rem; // 10px

$bold: 700;
$semi-bold: 600;
$medium: 500;
$regular: 400;

html {
  font-size: 16px; // set units for rem
}

h1,
h2,
h3,
h4,
h5,
h6,
p,
small,
b,
li {
  font-family: $main-font;
  font-weight: normal;
  font-style: normal;
  color: $black;
}

h1 {
  font-weight: $bold;
  font-size: 3.125rem;
  line-height: 4rem;
  color: $primary-red;
}

h1.sm {
  font-size: 1.625rem;
  color: $white;
  font-style: italic;
  font-weight: bold;
  letter-spacing: -0.24px;
}

h2.lg {
  font-weight: $semi-bold;
  font-size: 1.5rem;
  line-height: 2rem;
}

h2 {
  font-weight: $semi-bold;
  font-size: 1.125rem;
  line-height: 1.5rem;
}

h3 {
  font-weight: $semi-bold;
  font-size: 1rem;
  line-height: 1.5rem;
}

h4 {
  font-style: normal;
  font-weight: $semi-bold;
  font-size: 0.625rem;
}

p.caption {
  font-weight: $bold;
  font-size: 0.875rem;
  line-height: 1.25rem;
}

p.lg {
  font-size: 1rem;
  line-height: 1.5rem;
}

p {
  font-size: 0.875rem;
  line-height: 1.25rem;
}

p.description {
  font-size: 0.75rem;
  line-height: 1.125rem;
  color: $secondary-gray;
}

.muted {
  color: $secondary-gray;
}

.error {
  color: $primary-red;
}

.text-centered {
  text-align: center;
}

.semi-bold {
  font-weight: $semi-bold;
}

.bold {
  font-weight: $bold;
}
