@mixin x-sm-width {
  @media only screen and (min-width: 200px) and (max-width: 330px) {
    @content;
  }
}

@mixin sm-width {
  @media only screen and (min-width: 330px) {
    @content;
  }
}

@mixin md-width {
  @media only screen and (min-width: 360px) {
    @content;
  }
}

@mixin lg-width {
  @media only screen and (min-width: 400px) and (max-width: 500px) {
    @content;
  }
}

@mixin x-sm-height {
  @media only screen and (min-height: 500px) and (max-height: 599px) {
    @content;
  }
}

@mixin sm-height {
  @media only screen and (min-height: 600px) and (max-height: 699px) {
    @content;
  }
}

@mixin md-height {
  @media only screen and (min-height: 700px) {
    @content;
  }
}

@mixin lg-height {
  @media only screen and (min-height: 800px) {
    @content;
  }
}

@mixin phone-small {
  @media only screen and (max-width: 400px) and (max-height: 750px) {
    @content;
  }
}

@mixin phone-extra-small {
  @media only screen and (min-width: 320px) and (max-width: 359px) {
    @content;
  }
}

@mixin desktop {
  @media only screen and (min-width: 600px) {
    @content;
  }
}
