#bottom-drawer {
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  #drawer-background {
    height: 100%;
    width: 100%;
    position: absolute;
    background: $primary-dark-navi;
    opacity: 0.7;
    z-index: 20;
  }
  #drawer-content-wrapper {
    position: fixed;
    bottom: 0;
    left: 0;
    right: 0;
    min-height: 55vh;
    background: $primary-vanilla;
    border-radius: remr(1.25) remr(1.25) 0 0;
    border-top: $black;
    z-index: 21;
    padding: 1rem;

    overflow: none;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    &.full-screen {
      height: 90vh;
    }
    #drawer-content {
      min-height: 100%;
      overflow: auto;
      h2 {
        margin: remr(1.5) remr(2) remr(1.5);
        text-align: center;
        font-size: remr(1.5);
        line-height: remr(2);
      }
      p.lg {
        font-size: remr(1);
        line-height: remr(1.5);
      }
    }
    .action-icon-row {
      display: flex;
      justify-content: space-between;
      margin-bottom: 1rem;
      img {
        height: remr(1.125);
        opacity: 0.7;
      }
    }
    .buttons {
      margin: 1rem 0;
      button {
        height: 48px;
        margin-bottom: 1.5rem !important;
      }
      button.btn:last-child {
        border: 1px solid #6c6e7d !important;
        border-radius: 2px;
        background: transparent;
        color: $black;
      }
    }
  }
}
