.voting-location {
  margin-bottom: 2vh;
  p,
  b {
    font-size: 0.875rem;
  }
  .place {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    b {
      font-weight: bold;
    }
  }
  .address {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    p {
      margin: 0 !important;
    }
    margin: 0.25rem 0 1rem;
  }
  .opening-times {
    b {
      font-weight: bold;
    }
    p {
      margin-bottom: 0.5rem;
    }
  }
}
