#set-preference {
  main {
    padding: 0 1rem;
    @include desktop {
      max-width: 500px;
      margin: 0 auto;
    }
    #share-section {
      margin: 1.5rem 0 0.5rem;
      p {
        text-align: center;
        margin-bottom: 0;
        b {
          font-weight: bold;
          font-size: 0.875rem;
        }
      }
      > div {
        display: flex;
        justify-content: center;
        button.react-share__ShareButton {
          padding: 1rem !important;
          &:active,
          &:focus {
            border: none;
            outline: none;
          }
          svg {
            border-radius: 4px;
          }
        }
      }
    }
    #options-intro {
      margin: 2rem 0rem 1rem;
    }
    .selection-card {
      border-radius: 4px;
      background: #ffffff;
      box-shadow: 0px 4px 20px rgba(0, 0, 0, 0.1);
      margin-bottom: 1rem;
      .vbm-header {
        padding: 1.25rem;
      }
      header {
        position: relative;
        border-radius: 4px 4px 0 0;
        background: $black;
        color: $primary-vanilla;
        padding: 30px 1.25rem;
        margin-bottom: 0;

        h2 {
          color: $primary-vanilla;
          font-size: 1.5rem;
          line-height: 2rem;
          font-weight: 600;
          text-align: left;
          margin-bottom: 0;
        }

        #triangle-active {
          position: absolute;
          top: 0;
          right: 0;
          width: 0;
          height: 0;
          border-top: 3.5rem solid $primary-blue;
          border-left: 3.5rem solid transparent;
          z-index: 2;
          p {
            color: $primary-vanilla;
            font-size: 1.5rem;
            font-weight: 700;
            margin-top: -3rem;
            margin-left: -1.5rem;
          }
        }
        #triangle-passive {
          position: absolute;
          top: 0;
          right: 0;
          width: 0;
          height: 0;
          border-top: 2.5rem solid $secondary-gray;
          border-left: 2.5rem solid transparent;
          z-index: 2;
          p {
            color: $primary-vanilla;
            font-size: 0.875rem;
            font-weight: bold;
            margin-top: -2.25rem;
            margin-left: -1rem;
          }
        }
      }
      .content {
        padding: 1.25rem 1.25rem;
        .section {
          margin-bottom: 1.5rem;
          p {
            font-style: normal;
            font-weight: 400;
            font-size: 1rem;
            line-height: 1.5rem;
            margin-bottom: 0.65rem;
          }
          ul {
            padding-left: 1.25rem;
            margin-bottom: 0.65rem;
            li {
              font-size: 0.875rem;
              padding-bottom: 0.65rem;
              line-height: 1rem;
            }
            li:last-child {
              padding-bottom: 0;
            }
          }
          .vbm-vip-options-ul {
            margin-bottom: 1rem;
          }
          .vbm-vip-options-link {
            font-size: 0.875rem;
            line-height: 20px;
            color: $primary-red;
            margin-bottom: 0.75rem;
            cursor: pointer;
          }
        }

        button.btn.btn-primary.btn-block {
          font-size: 0.875rem;
          font-weight: 700;
          margin: 0 auto 0;
          padding: 0.75rem;
          display: block;
          border-radius: 4px;
        }
      }
      button.see-more {
        width: 100%;
        border: none;
        background: transparent;
        font-size: 0.875rem;
        color: $secondary-gray;
        padding: 6px;
      }
    }
  }

  div.donation-card {
    padding: 1.25rem 1.25rem;
    margin-bottom: 2rem;
    border-radius: 4px;
    border: solid 2px $secondary-gray;
    h2 {
      text-align: center;
      margin-bottom: 1rem;
    }
    a {
      &:hover {
        text-decoration: none;
      }
    }
    button {
      font-size: 0.875rem;
      font-weight: 700;
      margin: 0 auto 0;
      padding: 0.75rem;
      display: block;
      border-radius: 4px;
      margin-top: 1rem;
      background-color: $primary-blue;
      border-color: $primary-blue;
      &:hover {
        border: 1px solid transparent;
      }
    }
  }

  div.powered-by {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-bottom: 2rem;
    b {
      font-weight: bold;
      font-style: italic;
      font-size: 0.75rem;
      margin-right: 0.5rem;
    }
    img {
      height: 20px;
    }
  }
}
