#introduction {
  section {
    margin: 3rem 1.5rem 3rem 1rem;
    h2 {
      color: $primary-blue;
      font-style: italic;
      font-size: 1.5rem;
      line-height: 1.8rem;
      font-weight: 900;
      text-transform: uppercase;
      text-align: left;
    }
    p {
      font-weight: 600;
      color: $black;
      font-size: 1.125rem;
      line-height: 1.5rem;
      span {
        color: $primary-red;
      }
    }
  }
  h2 {
    color: $black;
    font-size: 1.125rem;
    line-height: 1.5rem;
    font-weight: 600;
    text-align: center;
  }
  img {
    width: 100%;
    height: auto;
    &.side-padding {
      padding: 0 1rem;
    }
  }
  button.join-us {
    width: 90vw;
    margin: 1rem;
    font-size: 0.875rem;
    padding: 0.75rem;
  }
  button#login-button {
    color: $primary-vanilla;
    background: transparent;
    border: none;
    font-size: 0.875rem;
    margin-right: 1rem;
  }
  button#faq-button {
    color: $primary-vanilla;
    background: transparent;
    display: inline;
    border: none;
    font-size: 0.875rem;
    margin-left: 1rem;
  }
  #top-wrapper {
    position: relative;
    // margin-top: remr(-6);
    height: 70vh;
    background: linear-gradient(180.05deg, #1d0830 62.03%, rgba(29, 8, 48, 0) 109.54%), url('/static/landslide/introduction/2.jpg');
    background-size: cover;
    margin-bottom: 5rem;
    div#top-wrapper-content {
      position: absolute;
      top: remr(1);
      width: 100vw;
      #top-row {
        display: flex;
        justify-content: space-between;
        align-items: center;
        h1 {
          font-style: italic;
          font-weight: 700;
          font-size: remr(1.625);
          margin-bottom: 0;
          color: $primary-vanilla;
        }
      }
      #has-captain {
        #invite-pic {
          width: 100vw;
          img {
            display: block;
            height: remr(11);
            width: remr(11);
            border-radius: 50%;
            border: remr(0.5) solid #332043;
            margin: remr(2) auto;
          }
          .initial {
            height: remr(11);
            width: remr(11);
            border-radius: 50%;
            background: #332043;
            margin: remr(2) auto;
            font-size: remr(5);
            display: flex;
            justify-content: center;
            align-items: center;
            p {
              padding-top: remr(0.5);
              font-size: remr(5);
            }
          }
        }
        p {
          padding: 0 remr(0.5);
          font-size: remr(1.125);
          line-height: remr(1.5);
          font-weight: 600;
          color: $primary-vanilla;
          text-align: center;
        }
      }
      #no-captain {
        margin-top: remr(4);
        h1 {
          margin: remr(1);
          margin-top: remr(2);
          color: $primary-blue;
          font-size: remr(2.5);
          line-height: remr(3);
          font-style: italic;
          font-weight: 900;
          text-transform: uppercase;

          span {
            color: $primary-vanilla;
          }
        }
        h3 {
          margin: remr(1);
          margin-top: remr(2);
          color: $primary-vanilla;
          font-size: remr(1.125);
          line-height: remr(1.5);
          font-weight: 600;
        }
        button {
          // margin-top: 4rem !important;
        }
      }
      button#gtm-intro-join-button-top {
        width: 92vw;
        margin: 1rem;
        padding: remr(1.125);
        font-size: remr(1);
      }
    }
    #star {
      position: absolute;
      bottom: remr(-3);
      left: 50%;
      -webkit-transform: translateX(-50%);
      transform: translateX(-50%);
      height: remr(6);
      width: remr(6);
    }
  }
  #has-no-invite {
    h2 {
      font-style: italic;
      font-weight: 700;
      font-size: 1.375rem;
      line-height: 1.625rem;
      color: $black;
    }
  }
  #has-invite {
    h2 {
      font-weight: 900;
      font-size: 1.5rem;
      line-height: 1.75rem;
      color: $primary-blue;
      font-style: italic;
      text-transform: uppercase;
      margin-bottom: 1rem;
    }
    h3 {
      font-weight: 600;
      font-size: 1.125rem;
      line-height: 1.5rem;
      color: $black;
      margin-bottom: 1.5rem;
      &:first-of-type {
        margin-bottom: 0;
      }
    }
    hr {
      margin-bottom: 2rem;
    }
    p {
      margin-right: 0;
    }
  }
  section.barchart-section {
    margin-bottom: 2rem;
    h2 {
      margin-right: 1rem;
      font-size: 1.375rem;
      line-height: 1.65rem;
      font-style: italic;
      color: $black;
      text-transform: none;
      font-weight: bold;
      margin-bottom: 0;
    }
    p {
      margin-right: 3rem;
      margin-top: 1rem;
      font-size: 1rem;
      line-height: 1.5rem;
      font-weight: 600;
      color: $secondary-gray;
    }
  }
  section.track-your-impact {
  }
  #bottom-cta {
    margin-bottom: 4rem;
    h2 {
      margin-bottom: 0;
    }
    button.btn.btn-primary.join-us {
      font-size: 1rem;
      padding: 1rem;
      font-weight: 600;
      width: 92vw;
    }
  }
  #faq {
    background: $black;
    margin-top: 3rem;
    .content {
      padding: 3rem 1.5rem 5rem 1rem;
      h2 {
        color: $primary-blue;
        font-style: italic;
        font-size: 1.5rem;
        line-height: 1.8rem;
        font-weight: 900;
        text-align: left;
        margin-bottom: 1.5rem;
      }
      button.btn {
        padding-left: 0;
        display: flex;
        margin-bottom: 0;
        padding-bottom: 0;
        p {
          color: $primary-vanilla;
          font-size: 1rem;
          font-weight: normal;
          line-height: 1.5rem;
          text-align: left;
          padding-left: 0;
          opacity: 1;
          &:first-of-type {
            width: 1rem !important;
          }
          &:nth-of-type(2) {
            width: 100%;
          }
        }
      }
      p {
        color: $primary-vanilla;
        opacity: 0.6;
        padding-left: 1rem;
        font-size: 1rem;
        line-height: 1.5rem;
      }
      button#gtm-intro-contact-us {
        margin-top: 3rem;
        background: $black;
        padding: 0.5rem 2rem;
        font-size: 0.75rem;
        color: $primary-vanilla;
        border: 1px solid $primary-vanilla;
        border-radius: 2px;
      }
    }
  }
}
