#warning-screen {
  margin: 5rem 2rem;
  height: 100vh;
  display: flex;
  h3 {
    font-size: 2rem;
    text-align: center;
    margin-bottom: 2rem;
  }
  p {
    font-size: 1.2rem;
    line-height: 1.5rem;
  }

  button {
    margin-top: 3rem;
    font-size: 1.2rem;
    padding: 0.5rem;
  }
}
