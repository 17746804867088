#tou,
#privacy {
  // margin-bottom: 5rem;
  max-width: 500px;
  margin: 2rem auto 5rem;
  h1 {
    // margin: 2rem 0;
    // color: $primary-dark-navi;
    font-size: 2rem;
    // text-align: center;
  }
  h2 {
    margin: 1.5rem 0 1rem;
  }
  span {
    text-decoration: underline;
  }
  table,
  th,
  td {
    border: 1px solid black;
  }
  td {
    padding: 0.5rem;
  }
}
