#state {
  .sdr {
    padding: 0;
    padding-bottom: 1rem;
    background: $secondary-gray;
    p {
      max-width: 500px;
      margin: 0 auto;
      text-align: center;
      padding: 1rem 2rem;
      @include desktop {
        padding: 1rem;
      }
      color: $primary-vanilla;
      font-size: 1rem;
      font-weight: 400;
      line-height: 1.5rem;
    }
    div {
      padding: 0.25rem;
      border: 1px solid $primary-vanilla;
      color: $primary-vanilla;
      text-align: center;
      font-weight: bold;
      font-size: 0.875rem;
      width: 10rem;
      margin: 0 auto;
      border-radius: 2px;
      &:hover {
        cursor: pointer;
      }
    }
  }
  header {
    background: white;
    padding: 1.25rem 1rem 0rem;
    @include desktop {
      padding-top: 2.125rem;
    }
    box-shadow: 0px 4px 20px rgba(0, 0, 0, 0.1);
    > div {
      margin-bottom: 1.5rem;
      max-width: 1000px;
      margin: 0 auto;
      .top-bar {
        display: flex;
        justify-content: space-between;
        align-items: center;
        @include desktop {
          margin-bottom: 2rem;
        }
        h1 {
          flex: 1 1 0px;
          font-size: 1.25rem;
          color: $primary-blue;
          line-height: 1.75rem;
          font-weight: 800;
          font-style: italic;
          margin-bottom: 0;
          text-align: center;
          @include desktop {
            font-size: 1.875rem;
          }
        }
        .back-button {
          flex: 1 1 0px;
          height: 1.125rem;
          padding: 0;
          display: flex;
          &:active,
          &:focus {
            outline: none;
          }
          img {
            height: 1.1rem;
          }
        }
        h3 {
          flex: 1 1 0px;
        }
        margin: 0 0 1rem;
      }
      .narrow-content {
        @include desktop {
          max-width: 500px;
          padding: 0 1rem;
          margin: 0 auto;
        }
      }
      h3 {
        font-size: 1.5rem;
        line-height: 2rem;
        color: $black;
        font-weight: 600;
        margin-bottom: 6px;
      }
      h4 {
        font-size: 1rem;
        line-height: 1.5rem;
        font-weight: 400;
      }
      button {
        border: none;
        background: transparent;
        font-size: 0.875rem;
        margin-bottom: 0;
        padding-left: 0;
        color: $secondary-gray;
      }
      img {
        height: 8px;
      }
      #recommendation-tabs {
        margin-top: 1rem;
        display: flex;
        justify-content: space-between;
        margin-left: -1rem;
        margin-right: -1rem;
        @include desktop {
          margin-left: 0;
          margin-right: 0;
        }
        button {
          border: none;
          outline: none;
          background: transparent;
          width: 100%;
          text-align: center;
          padding: 1rem 0 8px;
          color: $secondary-gray;
          font-weight: bold;
          font-size: 0.875rem;
          &.active {
            padding-bottom: 4px;
            border-bottom: 4px solid $primary-blue;
          }
        }
      }
    }
  }
}
