#user-form-reg {
  margin-bottom: 7rem;
  #user-form-top {
    background: $primary-dark-navi;
    padding: 1rem 0 2rem;
    position: relative;
    h2 {
      margin: 4vh 1rem 0 1rem;
      color: $white;
    }
    a {
      color: $primary-blue;
      margin-left: 1rem;
      font-size: 0.825rem;
      padding-bottom: 4rem;
    }
    div.logo-wrapper {
      position: relative;
      height: 7rem;
      width: 7rem;
      margin: 0 auto;
      img {
        margin: 2.5rem auto;
        margin-bottom: 0;
        height: 7rem;
        width: auto;
        display: block;
        border-radius: 50%;
      }
      .camera {
        height: 24px !important;
        position: absolute;
        bottom: 0.25rem;
        right: 0.25rem;
      }
    }
  }
  #form-container {
    padding: 0 1rem;
  }
}
