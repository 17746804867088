#screen-orientation {
  padding: 2rem;
  height: 100vh;
  width: 100vw;
  display: flex;
  justify-content: center;
  align-items: center;
  h1 {
    font-size: 2rem;
    margin-bottom: 2rem;
  }
  p {
    font-size: 1.5rem;
    line-height: 2rem;
  }
  h1,
  p {
    text-align: center;
  }
}
