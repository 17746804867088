#join-pages-header {
  margin-top: 4vh;
  img,
  div.placeholder {
    margin-left: 6px;
    height: 18px;
    padding-right: 20px;
  }
  h3 {
    margin: 0.75rem 0;
    font-size: 18px;
    font-weight: 600;
  }
}
