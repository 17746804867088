#state-information {
  header {
    background: white;
    padding: 1.25rem 1rem 1rem;
    box-shadow: 0px 4px 20px rgba(0, 0, 0, 0.1);
    margin-bottom: 1rem;
    @include desktop {
      padding-top: 2.125rem;
    }
    > div {
      max-width: 1000px;
      margin: 0 auto;
      .top-bar {
        display: flex;
        justify-content: space-between;
        align-items: center;
        @include desktop {
          margin-bottom: 1.25rem;
        }
        h1 {
          flex: 1 1 0px;
          font-style: normal;
          font-weight: 600;
          line-height: 1.5rem;
          font-size: 1.25rem;
          color: $black;
          margin-bottom: 0;
          text-align: center;
          @include desktop {
            font-size: 1.875rem;
          }
        }
        .back-button {
          flex: 1 1 0px;
          height: 1.125rem;
          padding: 0;
          display: flex;
          border: none;
          background: transparent;
          img {
            height: 1.1rem;
          }
        }
        h3 {
          flex: 1 1 0px;
        }
      }
    }
  }
  #details-section {
    padding: 0 1rem;
    max-width: 500px;
    margin: 1.5rem auto;
  }
}
