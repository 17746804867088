#overlay {
  position: fixed;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
  background: rgba(29, 8, 48, 0.9);
  z-index: 5;
  .centered-content {
    height: 100%;
    margin: 1rem;
    margin-top: 25vh;
    img {
      height: 57px;
    }
    h2 {
      margin-top: 2vh;
      margin-bottom: 2vh;
      font-size: remr(2.5);
      line-height: remr(3.125);
      color: $primary-vanilla;
      font-weight: $bold;
      text-align: left !important;
    }
    .failed-title {
      font-size: 1.125rem;
      line-height: 24px;
    }
    p {
      font-size: remr(1.5);
      line-height: remr(2);
      font-weight: $semi-bold;
      color: $primary-vanilla;
      span {
        color: $primary-red;
      }
    }
    button {
      padding: 1rem;
      font-size: 0.825rem;
      margin-top: 5vh !important;
    }
    a {
      color: $primary-vanilla;
      color: $primary-red;
      margin-top: 1rem;
    }
    .subtext {
      font-size: 1rem;
      color: $primary-vanilla;
    }
  }
}
