.webp {
  #enter-zip {
    background-image: linear-gradient(0deg, rgba(29, 8, 48, 0.4), rgba(29, 8, 48, 0.4)), url('/static/landslide/images/landing-bg.webp');
  }
}
#enter-zip {
  min-height: 100vh;
  display: flex;
  justify-content: space-between;
  flex-direction: column;
  background: $primary-dark-navi;
  background-image: linear-gradient(0deg, rgba(29, 8, 48, 0.4), rgba(29, 8, 48, 0.4)), url('/static/landslide/images/landing-bg.jpg');
  background-position: center center;
  background-size: contain;
  background-repeat: repeat;
  header {
    padding: 0.5rem 0rem 0;

    @include desktop {
      padding: 1.25rem 0 0;
    }
    h1 {
      color: $primary-vanilla;
      text-align: center;
      font-size: 1.25rem;
      line-height: 1.25rem;
      font-weight: 700;
      font-style: italic;
      margin: 1.25rem auto;
      @include desktop {
        font-size: 1.875rem;
      }
    }
    #copy-1 {
      h2 {
        font-size: 1.5rem;
        line-height: 2rem;
        color: $primary-vanilla;
        text-align: center;
        font-weight: 600;
        text-align: center;
        margin: 0 auto !important;
        padding: 0 2rem;
        @include desktop {
          font-size: 2.5rem;
          line-height: 3.125rem;
          max-width: 35rem;
          margin: 0 auto;
        }
        &.blue {
          margin: 0;
          padding: 0 -1rem;
          color: $primary-blue;
          @include desktop {
            font-size: 2.5rem;
            line-height: 3.125rem;
            max-width: 37rem;
            margin: 1rem auto 0;
          }
        }
      }
    }
    #copy-2 {
      margin: 1rem 0 -1rem;

      @include desktop {
        margin-bottom: 2rem;
      }
      h2 {
        font-size: 2rem;
        line-height: 2.625rem;
        color: $primary-vanilla;
        text-align: left;
        font-weight: bold;
        margin: 0 auto !important;
        padding: 0 1.5rem;
        &.blue {
          display: block;
          color: $primary-blue;
          // margin-right: 0.5rem;
          @include desktop {
            margin: 0 auto;
            // display: inline;
          }
        }
        @include desktop {
          text-align: center;
          max-width: 35rem;
          margin: 0 auto;
        }
      }
      h3 {
        font-size: 1.125rem;
        line-height: 1.5rem;
        color: $primary-blue;
        text-align: left;
        font-weight: 600;
        margin: 2rem 0 0.5rem !important;
        padding: 0 1.5rem;
        max-width: 25rem;
        @include desktop {
          text-align: center;
          margin: 2rem auto 0.5rem !important;
        }
      }
      p {
        font-size: 1rem;
        line-height: 1.5rem;
        color: $primary-vanilla;
        opacity: 0.7;
        text-align: left;
        font-weight: normal;
        margin: 0.2rem 0;
        padding: 0 1.5rem;
        max-width: 18rem;
        @include desktop {
          text-align: center;
          margin: 0.2rem auto !important;
        }
      }
    }
    #captain-img {
      margin: 1rem 1rem 0;
      img,
      .initial {
        display: block;
        margin: 1rem auto;
        border-radius: 50%;
        height: 10rem;
        width: 10rem;
        border: 10px solid rgba(109, 111, 126, 0.2);
        @include desktop {
          height: 6.5rem;
          width: 6.5rem;
        }
      }
      .initial {
        display: flex;
        justify-content: center;
        align-items: center;
        border: none;
        background: rgba(109, 111, 126, 0.2);
        p {
          font-size: 7rem;
          @include desktop {
            font-size: 5rem;
          }
        }
      }
      p {
        color: $primary-vanilla;
        font-size: 1.125rem;
        font-weight: 600;
        line-height: 1.5rem;
        text-align: center;
        margin-bottom: 0;
        @include desktop {
          max-width: 22rem;
          margin: 0 auto;
        }
      }
      .no-crew {
        height: 1rem;
        @include desktop {
          height: 2rem;
        }
      }
      .no-crew-copy-2 {
        height: 2rem;
      }
    }
  }
  main {
    margin: 0 auto;
    max-width: 550px;
    padding: 1.375rem 1rem 0;
    @include desktop {
      padding: 0;
    }
    h3 {
      margin-bottom: 1.25rem;
    }
    img {
      margin-top: 0.25rem;
      height: 0.875rem;
      margin-right: 0.75rem;
    }
    div.d-flex p {
      margin-bottom: 0.875rem;
    }
    form {
      margin-top: 2rem;
      p.error {
        margin: 1rem;
        color: $primary-red;
        text-align: center;
        font-size: 1rem;
        font-weight: bold;
      }
    }
    .zip-input.form-control {
      padding: 1.5rem;
      text-align: center;
      background: #fcfcfe;
      color: rgba(109, 111, 126, 0.5);
      font-weight: 600;
      border: 1px solid rgba(109, 111, 126, 0.5);
      border-radius: 5px;
      font-family: 'Poppins', sans-serif;
      max-width: 350px;
      margin: 0 auto;
      &:focus,
      &:active {
        outline: none;
        box-shadow: none;
      }
      &::placeholder {
        color: rgba(109, 111, 126, 0.5);
      }
    }
    button {
      padding: 0.75rem;
      font-size: 0.875rem;
      margin: 0 auto;
      max-width: 350px;
      opacity: 1;
      &:disabled {
        opacity: 0.7;
      }
      &:active {
        transform: scale(1);
      }
      &:hover {
        transform: scale(1);
        border: 1px solid transparent;
      }
    }
  }
  div.image-wrapper {
    height: 270px;
    position: relative;
    position: relative;
    width: 375px;
    max-width: 100vw;
    margin: 0 auto;
    @include desktop {
      height: 350px;
      width: 520px;
    }
    .biden img,
    .biden source {
      position: absolute;
      max-height: 250px;
      @include desktop {
        max-height: 330px;
      }
      left: 0;
      bottom: 0;
      z-index: 2;
    }
    .trump img,
    .turmp source {
      position: absolute;
      max-height: 170px;
      @include desktop {
        max-height: 230px;
      }
      bottom: 0;
      right: 0;
    }
  }
  footer {
    background: $primary-dark-navi;
    opacity: 0.9;
    p {
      padding-top: 1.125rem;
      margin-bottom: 0;
      font-style: italic;
      color: $primary-vanilla;
      font-weight: 700;
      font-size: 0.75rem;
      text-align: center;
      opacity: 0.5;
    }
    > div {
      display: flex;
      justify-content: center;
    }
    a {
      color: $primary-vanilla;
      font-weight: 500;
      font-size: 0.75rem;
      text-align: center;
      padding: 0.25rem 0.5rem 1rem;
      @include desktop {
        padding: 0.25rem 1rem 1rem;
      }
    }
  }
}
