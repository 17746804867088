#confirmation-code-input {
  form {
    margin: 1rem auto;
    display: flex;
    justify-content: center;
    input {
      margin: 0.5rem;
      font-size: 1.5rem;
      text-align: center;
      width: 3rem;
      background: transparent;
      border: none;
      border-bottom: 1px solid $secondary-gray;
      &:active,
      &:focus {
        outline: none;
        box-shadow: none;
      }
    }
  }
}
