#vbmMail-msg {
  font-size: 1.5rem;
  color: $black;
  font-weight: 600;
  margin-top: 3rem;
  margin-bottom: 1rem;
}
#recommendation {
  border-radius: 4px;
  background: #ffffff;
  box-shadow: 0 4px 4px rgba(46, 45, 45, 0.3);
  margin-bottom: 2rem;

  .vbm-header {
    padding: 1.25rem;
  }

  .subtitle {
    font-size: 0.875rem;
    font-weight: 400;
    color: $primary-vanilla;
    margin-bottom: 0;
  }

  header {
    position: relative;
    border-radius: 4px 4px 0 0;
    background: $black;
    color: $primary-vanilla;
    padding: 30px 1.25rem;
    margin-bottom: 0;
    text-align: left;
    cursor: pointer;
    h2 {
      color: $primary-vanilla;
      font-size: 1.5rem;
      line-height: 2rem;
      font-weight: 600;
      text-align: left;
      margin-bottom: 0;
    }

    #triangle-active {
      position: absolute;
      top: 0;
      right: 0;
      width: 0;
      height: 0;
      border-top: 3.5rem solid $primary-blue;
      border-left: 3.5rem solid transparent;
      z-index: 2;
      p {
        color: $primary-vanilla;
        font-size: 1.5rem;
        font-weight: 700;
        margin-top: -3rem;
        margin-left: -1.5rem;
      }
    }
    #triangle-passive {
      position: absolute;
      top: 0;
      right: 0;
      width: 0;
      height: 0;
      border-top: 2.5rem solid $secondary-gray;
      border-left: 2.5rem solid transparent;
      z-index: 2;
      p {
        color: $primary-vanilla;
        font-size: 0.875rem;
        font-weight: bold;
        margin-top: -2.25rem;
        margin-left: -1rem;
      }
    }
  }

  .content {
    padding: 1.25rem 1.25rem;

    #location-container {
      border-bottom-color: rgba(0, 0, 0, 0.1);
      border-bottom-style: solid;
      border-bottom-width: 1px;
      border-top-color: rgba(0, 0, 0, 0.1);
      border-top-style: solid;
      border-top-width: 1px;
      padding-bottom: 1.5rem;
      margin-top: 1.5em;
      h2 {
        margin-top: 1.5em !important;
      }
      a {
        font-size: 0.875rem;
      }
      #location {
        div.voting-location {
          margin-bottom: 0 !important;
        }
        p {
          margin-bottom: 0;
        }
      }
    }

    .section {
      margin-bottom: 1.5rem;
      h2 {
        margin-bottom: 0.5rem;
      }
      p {
        font-style: normal;
        font-weight: 400;
        font-size: 1rem;
        line-height: 1.5rem;
        margin-bottom: 0.65rem;
      }

      ul {
        padding-left: 1.25rem;
        margin-bottom: 0.75rem;
        li {
          font-size: 0.875rem;
          padding-bottom: 0.65rem;
          line-height: 1.25rem;
        }
        li:last-child {
          padding-bottom: 0;
        }
      }
      .vbm-vip-options-ul {
        margin-bottom: 1rem;
      }
      .vbm-vip-options-link {
        font-size: 0.875rem;
        line-height: 20px;
        color: $primary-red;
        margin-bottom: 0.75rem;
        cursor: pointer;
      }
      a {
        color: $primary-red;
      }
    }
    .section:last-of-type {
      margin-bottom: 0;
    }

    .info p {
      font-size: 0.875rem;
      line-height: 1.25rem;
    }

    button.btn.btn-primary.btn-block {
      font-size: 0.875rem;
      font-weight: 700;
      margin: 0 auto 0;
      padding: 0.75rem;
      display: block;
      border-radius: 4px;
      margin-top: 1rem;
    }
  }

  button.see-more {
    width: 100%;
    border: none;
    background: transparent;
    font-size: 0.875rem;
    color: $secondary-gray;
    padding: 6px;
  }
  h4.see-more {
    text-align: center;
    font-weight: 400;
    font-size: 14px;
    color: $secondary-gray;
    padding: 0.5rem;
    cursor: pointer;
  }
}
