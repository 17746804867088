#invitee {
  .invite-friend-button {
    display: flex;
    align-items: flex-start;
    flex-direction: column;
    background-color: inherit !important;
    margin-left: -16px !important;
    margin-bottom: 3vh !important;
    border: none;
    color: $black;

    &:hover {
      border: none !important;
    }

    &:disabled {
      opacity: 1 !important;
    }

    &:active {
      border: none !important;
    }

    &:focus {
      box-shadow: none !important;
    }

    &:not(:disabled):not(.disabled):active:focus,
    &:not(:disabled):not(.disabled).active:focus,
    show > &.dropdown-toggle:focus {
      box-shadow: none !important;
    }

    .invite-friend-container {
      display: flex;
    }

    .invite-icon {
      height: 19.34px;
      width: 18.33px;
      margin-top: 12px;
    }

    .invite-pic-container {
      width: 44px;
      height: 44px;
      border-radius: 50%;
      background: white;
    }

    .h3 {
      font-style: normal;
      line-height: 24px;
    }

    // needs to be reworked
    .invite-member-subtitle {
      color: $primary-red;
      margin: 0.3vh 0 0 2vh;
    }
  }
  .action-btns {
    #gtm-deny-member-btn {
      margin-right: 0.5rem;
      border: 1px solid $secondary-gray;
      color: $secondary-gray;
      &:hover {
        background: transparent;
      }
    }
  }
}
