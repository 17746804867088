#user-photo-upload {
  p {
    margin-top: 2rem;
    text-align: center;
    font-size: $description-medium;
    font-weight: 500;
    color: $secondary-gray;

    @include x-sm-height {
      margin-top: 0;
    }

    @include sm-height {
      margin-top: 1rem;
    }
  }
  .avatar-file-pick {
    position: relative;
    overflow: hidden;
    width: 40vw;
    height: 40vw;
    margin: 0 auto;
    border-radius: 50%;
    border: 1px solid $primary-dark-navi;
    box-shadow: 0px 4px 16px #f1f5f8;
    display: flex;
    justify-content: center;
    align-items: center;
    input.file-picker {
      width: 80vw;
      height: 80vw;
      border-radius: 50%;
      z-index: 2;
    }
    .camera-icon {
      position: absolute;
      top: calc(50%-8px);
      right: calc(50%-8px);
      width: 16px;
      height: 16px;
    }
  }
  .crop-container {
    position: relative;
    height: 60vw;
    width: 60vw;
    margin: 1rem auto;
    z-index: 3;
  }
  button#cancel {
    @extend .btn;
    @extend .btn-primary;
    @extend .centered;
    background: transparent;
    color: $primary-red;
    border: 1px solid $primary-red;
    border-radius: 2px;
    margin-top: 2rem;
  }
  button#skip {
    @extend .centered;
    margin-top: 3vh;
    background: transparent;
    border: none;
    color: $secondary-gray;
    font-size: 0.825rem;

    & :focus {
      outline: none !important;
    }

    @include sm-height {
      margin-top: 10vh;
    }

    @include md-height {
      margin-top: 19vh;
    }

    @include lg-height {
      margin-top: 26vh;
    }
  }
  img.profile {
    width: 40vw;
    border-radius: 50%;
    display: block;
    margin: 0 auto;
  }
  // .choose-new-photo::-webkit-file-upload-button {
  //   visibility: hidden;
  // }
  // .choose-new-photo::before {
  //   content: "Select some files";
  //   // display: inline-block;
  //   border: 1px solid #999;
  //   border-radius: 2px;
  //   padding: 5px 8px;
  //   outline: none;
  //   white-space: nowrap;
  //   -webkit-user-select: none;
  //   cursor: pointer;
  //   text-shadow: 1px 1px #fff;
  //   font-weight: 700;
  //   font-size: 10pt;
  //   width: 10rem;
  // }
  // .choose-new-photo:hover::before {
  //   border-color: black;
  // }
  // .choose-new-photo:active::before {
  //   background: -webkit-linear-gradient(top, #e3e3e3, #f9f9f9);
  // }
  .inputfile {
    width: 0.1px;
    height: 0.1px;
    opacity: 0;
    overflow: hidden;
    position: absolute;
    z-index: -1;
  }
  .inputfile + label {
    display: block;
    margin: 1rem auto;
    border-radius: 2px;
    text-align: center;
    padding: 0.25rem;
    width: 12rem;
    box-sizing: border-box;
    font-family: inherit;
    overflow: visible;
    text-transform: none;
    text-align: center;
    vertical-align: middle;
    user-select: none;
    padding: 0.375rem 0.75rem;
    line-height: 1.5;
    transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
    margin-bottom: 0.25rem !important;
    display: block;
    margin: 0 auto;
    font-weight: bold;
    font-size: 0.75rem;
    background: transparent;
    color: #ff696d;
    border: 1px solid #ff696d;
    border-radius: 2px;
    margin-top: 2rem;
    cursor: pointer;
  }

  .inputfile:focus + label,
  .inputfile + label:hover {
    // background-color: red;
  }
}
