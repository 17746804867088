@import url('https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');

.start-btn {
  position: absolute;
  width: 91.47%;
  left: 4.427%;
  right: 4.427%;
  bottom: 4vh;
  height: 6vh;
  padding-top: 6px;
  border-radius: 2px;
  border-color: $primary-red;
  background: $primary-red;
  font-family: $main-font;
  font-style: normal;
  font-weight: bold;
  font-size: $main-text;
  color: $white;
  line-height: 3.33vh;

  &:hover {
    box-shadow: 0 4px 4px rgba(46, 45, 45, 0.3);
    cursor: pointer;
    color: #ffffff;
    background: #ff696d !important;
    border: none !important;
    outline: none;
  }

  &:focus {
    outline: none;
    background: inherit;
  }

  &[disabled] {
    background-color: #f8e2dc !important;
    border: none;
  }

  &[disabled]:hover {
    box-shadow: none !important;
    border: none;
  }

  @include phone-small {
    font-size: 12px;
  }
  .start-btn[disabled] {
    background-color: $secondary-soft-red !important;
    border: none;
  }
}

.header-container {
  position: absolute;
  display: flex;
  justify-content: flex-start;
  flex-direction: column;
  width: 91.47%;
  left: 4.427%;
  right: 4.427%;
  margin-top: 31px;

  @include lg-height {
    min-width: 80%;
    width: 343px;
    margin-top: 62px;
  }
}

.arrow-icon {
  width: 1.5vh;
  height: 2.5vh;
}

.header-title {
  margin-top: 14.99px;
  font-family: $main-font;
  font-style: normal;
  font-weight: 600;
  font-size: 1.124rem;
  line-height: 28px;

  @include phone-extra-small {
    margin-top: 12px;
    font-size: $main-text !important;
  }

  @include phone-small {
    font-size: 16px;
    line-height: 24px;
  }
}

.tabs {
  position: absolute;
  width: 91.47%;
  left: 4.427%;
  right: 4.427%;
  height: 3px;
  top: 140px;
  display: flex;
}

.form-check {
  @include phone-small {
    padding-left: 0px;
  }
}
