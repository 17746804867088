#my-options {
  header {
    background: white;
    padding: 1.25rem 1rem 0.75rem;
    box-shadow: 0px 4px 20px rgba(0, 0, 0, 0.1);
    margin-bottom: 1.5rem;
    @include desktop {
      padding-top: 2.125rem;
    }
    > div {
      max-width: 1000px;
      margin: 0 auto;
      div.narrow {
        max-width: 500px;
        margin: 0 auto;
        @include desktop {
          padding: 0 1rem;
        }
      }
      #inapp-header {
        height: 3.2rem;
      }
      #top-bar {
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin: 0 0 2rem;
        @include desktop {
          margin-bottom: 1.25rem;
        }
        h1 {
          flex: 1 1 0px;
          font-style: normal;
          font-weight: 600;
          line-height: 1.5rem;
          font-size: 1.25rem;
          margin-bottom: 0;
          color: $black;
          text-align: center;
          @include desktop {
            font-size: 1.875rem;
          }
        }
        .back-button {
          height: 1.125rem;
          padding: 0;
          display: flex;
          border: none;
          background: transparent;
          img {
            height: 1.1rem;
          }
        }
        h3 {
          flex: 1 1 0px;
        }
      }
    }
    button {
      border: none;
      background: transparent;
      font-size: 0.875rem;
      margin-bottom: 0;
      padding-left: 0;
      color: $secondary-gray;
    }
    p {
      font-style: normal;
      font-weight: 400;
      font-size: 1rem;
      line-height: 1.5rem;
      margin-bottom: 0.65rem;
    }
    ul {
      padding-left: 1.25rem;
      margin-bottom: 0.65rem;
      li {
        font-size: 0.875rem;
        padding-bottom: 0.65rem;
        line-height: 1rem;
      }
    }
  }

  main {
    padding: 0 1rem;
    max-width: 500px;
    margin: 0 auto;
  }
}
