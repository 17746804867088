#power {
  min-height: 100vh;
  h1 {
    left: 4.427%;
    z-index: 5;
    font-size: remr(3);
    margin-top: remr(10);
  }

  #commited {
    color: $primary-blue;
  }

  #did-not-vote {
    margin-top: remr(2);
    color: $secondary-green;
  }

  .h2 {
    left: 4.427%;
    z-index: 5;
    font-size: remr(2);
  }

  #commited-sub {
    color: $primary-vanilla;
    margin-top: remr(-1.5);
    max-width: 60vw !important;

    @include x-sm-height {
      max-width: 80vw !important;
    }

    @include md-height {
      margin-top: remr(-1);
    }
  }

  .committed-icon {
    margin-top: 1.2vh;
    color: $secondary-gray;
  }

  #did-not-vote-sub {
    color: $primary-vanilla;
    margin-top: remr(-1.5);
    max-width: 63vw !important;

    @include x-sm-height {
      max-width: 80vw !important;
    }

    @include md-height {
      margin-top: remr(-1);
    }
  }

  .share-button {
    position: fixed;
    font-size: remr(0.77);
    height: 6.66vh;
    width: 91.47%;
    left: 4.427%;
    right: 4.427%;
    top: 65vh;
    line-height: 24px;
    text-align: left;
    z-index: 10;
    box-shadow: 0 4px 4px rgba(46, 45, 45, 0.3);

    &:hover {
      box-shadow: 0 4px 4px rgba(46, 45, 45, 0.3);
      cursor: pointer;
      color: $white;
      background: $primary-red !important;
      border: none !important;
      outline: none;
    }

    &:focus {
      outline: none;
      background: inherit;
    }

    @include sm-height {
      top: 61vh;
    }
  }

  .share-icon {
    float: right;
    margin-top: 2px;
  }

  h3 {
    left: 4.427%;
    position: fixed;
    top: 73vh;
    max-width: 89vw;
    // font-size: remr(0.2);
    @include x-sm-height {
      font-size: remr(1.33);
      left: 10%;
      max-width: 88vw;
      line-height: 16px;
    }
  }
}
