.custom-map-modal {
  .modal-dialog {
    position: fixed;
    top: 50%;
    left: 0;
    border: none;
    width: 100vw;
    height: 50vh;
    margin: 0;
  }
  .modal-content {
    background: $primary-vanilla;
    height: auto;
    min-height: 100%;
    border-top-left-radius: 20px;
    border-top-right-radius: 20px;
  }
}

// Can't target the modal through id targeting
// -> maybe related to bootstrap?
.modal-text {
  position: relative;
  left: 50%;
  transform: translateX(-50%);
  margin-top: 8vh;
  text-align: center;
  font-size: 24px;
  font-weight: 600;
  line-height: 32px;
  max-width: 61vw;
}

.reg-arrow-icon {
  position: fixed;
  height: 2.5vh;
  // change color
  color: $secondary-gray;
}
.close-icon {
  position: fixed;
  right: 4vw;
  height: 2.5vh;
  color: $secondary-gray;
}

.modal-btn-container {
  margin-top: 7vh;
}

.modal-btn {
  font-size: $main-text;
  font-weight: $bold;
  height: 6vh;
  margin-bottom: 2vh !important;

  // can't target the second button
  &:nth-of-type(2) {
    background: $primary-vanilla;
    border: 1px solid #6c6e7d;
    color: $secondary-gray;
  }
}
