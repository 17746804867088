#user-form-lg {
  // padding: 1rem;
  margin-bottom: 2rem;
  // copied over from _user-form.scss
  p {
    font-size: 0.825rem;
    color: $secondary-gray;
    margin: 1.75rem 0;
  }
  .custom-input {
    height: 58px;
    border-bottom: solid 1px $primary-dark-navi;
    margin-top: 10px;
    margin-bottom: 20px;
  }

  .custom-label {
    height: 17px;
    font-family: $main-font;
    font-size: $description-medium;
    color: $black;
    font-style: normal;
    font-weight: bold;
    line-height: 20px;
  }

  .custom-input input {
    background-color: transparent;
    border: none;
    font-size: 14px;
    color: $black;
    line-height: 21px;
    box-shadow: none;
    padding-left: 0;
  }

  .custom-input input:focus {
    outline: none;
  }
  // state select styling
  select#state-select.form-control {
    background: transparent;
    border: none;
    padding-left: 0;
    option {
    }
  }
  form small.form-text {
    font-weight: bold;
    color: $primary-red;
  }
  button.btn.btn-primary {
    // font-size: 0.875rem;
    padding: 1rem;
    font-size: 0.825rem;
  }
  p.error-text {
    color: $primary-red;
    font-size: 1rem;
    font-weight: bold;
    text-align: center;
  }
  .row {
    margin: -15px;
  }
}
