#iframe-wrapper {
  z-index: 5;
  background: #102e51;
  #iframe-nav {
    background: $primary-vanilla;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    z-index: 6;
    padding: 1rem;
    p,
    h2 {
      color: $primary-dark-navi;
    }
    h2 {
      font-size: 1.625rem;
      font-style: italic;
      font-weight: bold;
      margin-bottom: 0;
      margin-top: 0.25rem;
    }

    button.img-wrapper {
      img {
        height: 1.125rem;
        width: auto;
        margin-bottom: 0.2em;
        margin-right: 0.7em;
      }
      color: $primary-dark-navi;
      border: none;
      background: transparent;
      font-weight: bold;
    }
  }
  #check-voter-reg-iframe {
    margin-top: 5rem;
    overflow: auto;
    min-height: 100vh;
    padding: 0.5rem;
  }
  #spinner-container {
    height: 50vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }
}
