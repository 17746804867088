#welcome-message-container {
  position: absolute;
  top: 0;
  width: 100vw;
  min-height: 100vh;
  z-index: 4;
  #welcome-message {
    div.content {
      padding: 0 0.625rem;
      img {
        display: block;
        margin: 0 auto 1rem;
        height: 4rem;
        width: auto;
      }
      h3,
      h4 {
        color: $primary-red;
        font-weight: 600;
        font-size: 1.125rem;
        line-height: 1.5rem;
        text-align: center;
      }
      h4 {
        color: $black;
      }
      p {
        font-size: 0.875rem;
        line-height: 1.25rem;
        text-align: center;
        color: $secondary-gray;
        margin-bottom: 5rem;
      }
    }
    button.btn.btn-primary.btn-block {
      padding: 0.75rem;
      font-size: 0.875rem;
      margin-bottom: 1rem !important;
    }
    button.btn.btn-primary.btn-block.transparent {
      font-size: 0.875rem;
      background: $primary-vanilla;
      color: $secondary-gray;
      border: 1px solid $secondary-gray;
    }
  }
}
