#crew-tabs {
  width: 100%;
  position: sticky;
  position: -webkit-sticky;
  top: 4.5rem;
  background: $primary-vanilla;
  display: flex;
  justify-content: space-around;
  align-items: center;
  z-index: 2;

  #my-crew-tab {
    margin-left: 1rem;
  }

  #up-crew-tab {
    margin-right: 1rem;
  }

  button {
    width: 50%;
    border: none;
    padding: 2vh 1vw 0 1vw;
    background: transparent;
    &:active {
      outline: none;
    }
  }

  .active-btn {
    border-bottom: 3px solid;
    border-radius: 1px;

    &:focus {
      outline: none !important;
    }
  }

  p {
    font-weight: bold;
    font-style: normal;
    color: $black;
    text-align: center;
    &.muted {
      color: $secondary-gray;
    }
  }
}
