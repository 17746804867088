#progress-tabs {
  display: flex;
  width: 100%;
  padding: 0.5rem 0 1rem 0;
  .progress-tab {
    height: 3px;
    width: 33%;
    border-radius: 1px;
    background: $secondary-soft-blue;
    margin: 0.2rem;
    &:first-child {
      margin-left: 0;
    }
    &:last-child {
      margin-right: 0;
    }
    &.complete {
      background: $primary-blue;
    }
  }
}
