#dashboard {
  .details-container {
    margin: 0 0 0 5vw;
  }

  .crew-container-mvp {
    margin: 0.5vh 0 15vh 5vw;
  }
}

#crew {
  .crew-container {
    margin-top: 1.5rem;
    margin-bottom: 6rem;
  }
}
