#join-form-v2 {
  .content {
    padding: 0 1rem;
    margin-bottom: 2rem;
    // copied over from _user-form.scss
    p.next b {
      font-weight: 600;
      font-size: 1.125rem;
      line-height: 1.5rem;
    }
    p {
      font-size: 0.825rem;
      color: $secondary-gray;
      margin: 1.75rem 0;
    }
    .custom-input {
      height: 58px;
      border-bottom: solid 1px $primary-dark-navi;
      margin-top: 10px;
      margin-bottom: 20px;
    }

    .custom-label {
      height: 17px;
      font-family: $main-font;
      font-size: $description-medium;
      color: $black;
      font-style: normal;
      font-weight: bold;
      line-height: 20px;
    }

    .custom-input input {
      background-color: transparent;
      border: none;
      font-size: 14px;
      color: $black;
      line-height: 21px;
      box-shadow: none;
      padding-left: 0;
    }

    .custom-input input:focus {
      outline: none;
    }
    // state select styling
    select#state-select.form-control {
      background: transparent;
      border: none;
      padding-left: 0;
      option {
      }
    }
    form small.form-text {
      font-weight: bold;
      color: $primary-red;
    }
    button.btn.btn-primary {
      // font-size: 0.875rem;
      padding: 1rem;
      font-size: 0.825rem;
    }
    p.error-text {
      color: $primary-red;
      font-size: 1rem;
      font-weight: bold;
      text-align: center;
    }
    p.description {
      font-size: 0.75rem;
      weight: 500;
    }
    .row {
      margin: -15px;
    }
    div.powered-by {
      display: flex;
      justify-content: center;
      align-items: center;
      b {
        font-weight: bold;
        font-style: italic;
        font-size: 0.75rem;
        margin-right: 0.5rem;
      }
      img {
        height: 20px;
      }
    }
  }
  header {
    background: white;
    box-shadow: 0px 4px 20px rgba(0, 0, 0, 0.1);
    margin-bottom: 1rem;
    max-width: 1000px;
    margin: 0 auto;
    padding-top: 0;
    .top-bar {
      display: flex;
      align-items: center;
      h1 {
        flex: 1 1 0px;
        font-style: normal;
        font-weight: 600;
        font-size: 1rem;
        color: $black;
        margin-bottom: 0;
        span {
          padding-left: 0.25rem;
          color: $primary-blue;
        }
      }
      .back-button {
        height: 1.125rem;
        padding: 0;
        padding-right: 0.75rem;
        display: flex;
        border: none;
        background: transparent;
        img {
          height: 1.1rem;
        }
      }
      h3 {
        flex: 1 1 0px;
      }
    }
  }
}
