#track-friend-form-container {
  position: fixed;
  top: 0;
  width: 100vw;
  max-height: 100vh;
  z-index: 4;

  #track-friend-form {
    margin-top: 4rem;
    background: #f7f7f0;
    min-height: 91vh;
    .content {
      padding: 3.5rem 1rem 0 1rem;
      min-height: 100vh;
      h4 {
        color: $black;
        font-weight: 600;
        font-size: $h3-semibold;
        line-height: 1.5rem;
        text-align: left;
      }
      p {
        font-size: 0.875rem;
        line-height: 1.25rem;
        text-align: left;
        color: $secondary-gray;
        margin-bottom: 5rem;
      }
      #form {
        .custom-input {
          height: 58px;
          border-bottom: solid 1px $primary-dark-navi;
          margin-top: 10px;
          margin-bottom: 28px;
        }

        .custom-label {
          height: 17px;
          font-family: $main-font;
          font-size: $description-medium;
          color: $black;
          font-style: normal;
          font-weight: bold;
          line-height: 20px;
        }

        .custom-input input {
          background-color: transparent;
          border: none;
          font-size: 14px;
          color: $black;
          line-height: 21px;
          box-shadow: none;
          padding-left: 0;
        }

        .custom-input input:focus {
          outline: none;
        }
      }
    }
  }

  .btn-wrapper {
    margin: 1rem auto;
  }

  button.btn.btn-primary.btn-block {
    padding: 0.75rem;
    font-size: 0.875rem;
    margin-bottom: 1rem !important;
  }
  button.btn.btn-primary.btn-block.transparent {
    font-size: 0.875rem;
    background: $primary-vanilla;
    color: $secondary-gray;
    border: 1px solid $secondary-gray;
  }
}
