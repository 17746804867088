#gtm-invite-member-btn {
  .invite-friend-button {
    display: flex;
    align-items: flex-start;
    align-content: space-between;
    margin-bottom: 1.5rem;
  }

  .invite-adjust {
    margin-bottom: 0.5rem;
  }

  &.member-container {
    margin-bottom: 0.5rem;
  }
  .invite-friend-container {
    display: flex;
  }
  .profile-pic-container {
    border-radius: 0;
    img {
      height: 2.8rem;
      width: auto;
    }
  }
  h3 {
    margin-top: 0.2rem;
    margin-bottom: 0;
  }
  .crew-status p {
    margin-top: -0.2rem;
    color: $primary-red;
    font-weight: 500;
    font-size: 0.75rem;
  }
}

#pending-invites {
  .profile-pic-container {
    background: none;
    border-radius: 0;
    img {
      margin-left: 0.2rem;
      height: auto;
      width: 3.6rem;
    }
  }
  h3 {
    margin-top: 0.75rem; // temp fix, should really be written with flex align items center;
  }
  span {
    color: $primary-red;
  }
}
