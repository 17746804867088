.background-1 {
  background: linear-gradient(rgba(29, 8, 48, 0.8), rgba(29, 8, 48, 0.8)), url(/static/landslide/intro/nathan-dumlao-KQ4V6TxLz90-unsplash%201.png);
  position: absolute;
  height: 100%;
  width: 101%;
}

.background-2 {
  background: linear-gradient(rgba(29, 8, 48, 0.95), rgba(29, 8, 48, 0.95)), url(/static/landslide/intro/nathan-dumlao-KQ4V6TxLz90-unsplash%201.png);
  position: absolute;
  height: 100%;
  width: 101%;
  z-index: -2;
}

.background-4 {
  background: linear-gradient(rgba(29, 8, 48, 0.8), rgba(29, 8, 48, 0.8)), url(/static/landslide/intro/nathan-dumlao-XFJUnkjn2HY-unsplash%201.png);
  position: absolute;
  height: 100%;
  width: 101%;
}

.login-btn {
  position: absolute;
  right: 2.3vh;
  top: 6vh;
  background: initial;
  &:hover {
    background: none !important;
  }
  &:focus {
    background: none !important;
  }
  &:active {
    background: none !important;
  }

  border: none;
  font-family: $main-font;
  font-style: normal;
  font-weight: normal;
  font-size: 0.875rem;
  line-height: 24px;
  text-align: right;
  color: $primary-vanilla;

  &:hover {
    background: none !important;
  }

  &:focus,
  &:active,
  &:active:focus:not(:disabled):not(.disabled),
  &:focus,
  &:active,
  &:hover {
    box-shadow: none !important;
    outline: 0;
    background-color: none !important;
  }

  @include phone-small {
    font-size: 11px;
  }

  @include lg-height {
    top: 58px;
  }
}

.landslide-title-landing {
  position: absolute;
  height: 20px;
  // width: 91.47%;
  left: 2.25vh;
  right: 2.25vh;
  top: 18vh;
  font-family: $main-font;
  font-size: $landslide-title;
  font-style: italic;
  font-weight: bolder;
  color: $primary-blue;
  line-height: 20px;
  letter-spacing: -0.24px;

  @include lg-height {
    top: 161px;
  }
}

.index-subtitle {
  position: absolute;
  width: 44vh;
  height: 58.67vh;
  left: 2.21vh;
  right: 5vh;
  top: 25vh;
  font-family: $main-font;
  color: $primary-vanilla;
  font-size: $h1-semibold;
  font-style: normal;
  font-weight: bold;
  line-height: 6vh;

  @include phone-extra-small {
    font-size: 28px;
  }

  @include phone-small {
    font-size: 32px;
  }

  @include lg-height {
    top: 206px;
    line-height: 50px;
    height: 423px;
  }
}

.new-votes-title {
  position: absolute;
  height: 17vh;
  left: 2.3vh;
  right: 2.3vh;
  margin-top: 13vh;
  color: $primary-vanilla;
  font-family: $main-font;
  font-size: $h2-semibold;
  font-style: normal;
  font-weight: 600;

  @include phone-extra-small {
    font-size: 1.1rem !important;
  }

  @include phone-small {
    font-size: 1.3rem;
  }

  @include lg-height {
    line-height: 4vh;
    margin-top: 150px;
  }
}

.closer-subtitle {
  color: $primary-vanilla;
  position: absolute;
  width: 322px;
  height: 31px;
  left: 2.21vh;
  top: 31vh;
  font-family: $main-font;
  font-size: $h2-semibold;
  font-style: normal;
  font-weight: 600;
  line-height: 32px;

  @include phone-extra-small {
    font-size: 1.1rem !important;
  }

  @include phone-small {
    font-size: 1.3rem;
  }

  @include lg-height {
    top: 300px;
  }
}

.votes-container {
  position: absolute;
  width: 91.47%;
  left: 4.427%;
  right: 4.427%;
  top: 38vh;

  @include lg-height {
    top: 360px;
  }

  @include lg-width {
    width: 84%;
    left: 8%;
    right: 8%;
  }
}

.closer-fact-container {
  position: relative;
  background: rgba(98, 198, 221, 0.2);
  margin-bottom: 2.77vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.rectangle-style {
  position: absolute;
  width: 11px;
  height: 22px;
}

.closer-fact {
  font-family: $main-font;
  color: $primary-vanilla;
  font-weight: 300;
  font-size: $h3-semibold;
  font-weight: 300;
  line-height: 3.33vh;
  margin-left: 2.21vh;
  margin-right: 2.21vh;
  margin-top: 1.66vh;
  margin-bottom: 1.34vh;

  @include phone-extra-small {
    font-size: 1rem !important;
  }

  @include phone-small {
    font-size: 1.2rem;
  }
}

.bold-red {
  color: $primary-red;
  font-weight: bold;
}

.li-bold {
  font-weight: bold;
}

.invite-description {
  position: absolute;
  width: 292px;
  height: 72px;
  left: 2.3vh;
  top: 29vh;
  font-family: $main-font;
  color: $primary-vanilla;
  font-size: $h3-semibold;
  font-style: normal;
  font-weight: 300;
  line-height: 24px;

  @include phone-extra-small {
    top: 200px !important;
    font-size: 16px !important;
  }

  @include phone-small {
    top: 220px;
  }

  @include lg-height {
    top: 260px;
  }
}

.landslide-graphic {
  position: absolute;
  width: 320px;
  height: 275.2px;
  top: 40vh;
  margin-left: auto;
  margin-right: auto;
  left: 0;
  right: 0;
  text-align: center;

  @include phone-extra-small {
    top: 290px !important;
    width: 200px !important;
    height: 173.607038122px !important;
  }

  @include phone-small {
    top: 324.85px;
    width: 250px;
    height: 205.67px !important;
  }

  @include lg-height {
    top: 367px !important;
    width: 341px;
    height: 296px;
  }
}

.until-election-title {
  position: absolute;
  width: 40vh;
  height: 154px;
  left: 16px;
  top: 118px;
  color: $primary-vanilla;
  font-size: $h1-semibold;
  font-family: $main-font;
  font-style: normal;
  font-weight: bold;
  line-height: 7vh;

  @include phone-extra-small {
    font-size: 30px;
    width: 200px;
  }

  @include lg-height {
    top: 153px;
  }
}

.until-election-subtitle {
  position: absolute;
  width: 45vh;
  height: 26.63vh;
  left: 4.427%;
  top: 303px;
  color: $primary-vanilla;
  font-family: $main-font;
  font-size: $h2-semibold;
  font-style: normal;
  font-weight: 600;
  line-height: 4vh;

  @include phone-extra-small {
    top: 250px !important;
  }

  @include phone-small {
    top: 275px;
  }

  @include lg-height {
    top: 338px;
  }
}

.with-us {
  position: absolute;
  width: 327px;
  height: 32px;
  left: 4.427%;
  top: 543px;
  color: $primary-vanilla;
  font-family: $main-font;
  font-size: $h2-semibold;
  font-style: normal;
  font-weight: 600;
  line-height: 32px;

  @include phone-extra-small {
    top: 450px !important;
  }

  @include phone-small {
    top: 500px;
  }

  @include lg-height {
    top: 573px;
  }
}

.desktop {
  margin-top: 200px;
  text-align: center;
  font-family: $main-font;
  color: $black;
  font-size: $h1-semibold;
  color: $primary-vanilla;
}

.desktop-sub {
  margin-top: 250px;
  text-align: center;
  font-family: $main-font;
  font-size: $h3-semibold;
  color: $primary-vanilla;
}
