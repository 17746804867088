#extended-voting-info {
  max-width: 500px;
  margin: 0.5rem auto 0;
  padding: 1rem 1rem 0;
  ul {
    padding-left: 1.25rem;
    li {
      font-size: 0.875rem;
      margin-bottom: 0.75rem;
    }
  }
  #see-something {
    padding: 1rem;
    margin-bottom: 2rem;
    background-color: $secondary-soft-blue;
    p {
      margin: 0;
    }
    button {
      margin-top: 2vh;
      border-radius: 4px;
      padding: 0.7vh 1rem;
      font-weight: bold;
      background: $secondary-soft-blue;
      border: 1px solid #6c6e7d;
      color: #6c6e7d;
    }
  }
}
