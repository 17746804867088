#crew-details {
  .member-container {
    display: flex;
    align-items: flex-start;
    align-content: space-between;
    margin-bottom: 1.5rem;
  }

  .profile-pic-container {
    flex: 0 0 44px;
    display: flex;
    width: 44px;
    height: 44px;
    margin-top: 3px;
    border-radius: 50%;
    overflow: hidden;
    box-sizing: border-box;
    justify-content: center;
    align-items: center;
  }

  .profile-pic {
    display: inline;
    margin: 0 auto;
    max-height: 100%;
    max-width: 100%;
  }

  .fallback-profile-pic {
    background: white;
    display: flex;
    justify-content: center;
    align-items: center;
    img {
      height: 3rem;
      width: auto;
    }
  }
  .s-container {
    display: flex;
    flex-direction: column;
  }

  .member-row {
    display: flex;
    flex-wrap: nowrap;
    flex-direction: row;
    justify-content: space-between;
    width: 80vw;
    height: 2rem;
  }

  .name-row {
    display: flex;
    flex-wrap: nowrap;
    flex-direction: row;
  }

  h3 {
    margin: 0 0 0 2vh;
    width: max-content;
  }

  .dashboard-title {
    display: inline-block;
    box-sizing: border-box;
    padding: 0.55vh 0.55vh 0.55vh 0.55vh;
    margin: 0.2vh 0 0 2vw;
    height: 20px;
    border-radius: 2px;
  }

  .captain {
    background: $secondary-soft-blue;
  }

  .you {
    background: $secondary-soft-red;
  }

  h4 {
    position: static;
    left: 8%;
    right: 8%;
    text-align: center;
    color: $primary-blue;
  }

  .you-h4 {
    color: $primary-red;
  }

  .check-status-btn {
    justify-content: flex-end;
    height: max-content !important;
    border-radius: 4px;

    &:hover {
      cursor: pointer;
      color: #ffffff;
      background: #ff696d !important;
      border: none !important;
      outline: none;
    }

    &:focus {
      outline: none;
      background: inherit;
    }
  }

  .nudge-btn {
    // margin-top: 0.5rem;
    height: max-content;
    background: $primary-vanilla;
    border: 1px solid $secondary-gray;
    color: $secondary-gray !important;
    border-radius: 4px;

    &:hover {
      cursor: pointer;
      color: $secondary-gray !important;
      background: inherit !important;
      outline: none;
      border: 1px solid $secondary-gray !important;
    }

    &:focus {
      outline: none !important;
      background: inherit;
      border: 1px solid $secondary-gray !important;
    }

    &:active {
      background-color: inherit !important;
      border-color: $secondary-gray !important;
    }
  }

  .crew-status {
    margin: 0 0 0 2vh;
  }

  .invite-subtitle {
    margin: 0.5vh 0 0 2vh;
  }

  .description {
    margin-bottom: 1.3vh;
  }

  .crew-status-icon {
    width: 12px;
    height: 12px;
    margin-bottom: 5px;
  }
  // VotingsStatus.jsx
  .status-container {
    p {
      @extend .description;
      font-weight: 500;
      margin: 0 0 0.5rem 2vh;
      .registration-status-icon {
        height: 16px;
        width: auto;
        margin: 0 5px 2px 0;
      }
    }
    .green {
      color: $secondary-green;
    }
  }
  button.btn.btn-primary.text-white {
    padding-bottom: 1.5rem !important;
    div {
      color: white;
    }
  }
}
