body {
  background: $primary-vanilla;
  color: $primary-dark-navi;
  font-family: 'Poppins', sans-serif;
}

.d-flex {
  display: flex;
}

.jcsb {
  justify-content: space-between;
}

.jcc {
  justify-content: center;
}

.d-end {
  justify-content: flex-end;
}

.aic {
  align-items: center;
}

.w-50 {
  width: 50%;
}

#error-page,
#four0four {
  height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  h2 {
    margin-bottom: 2rem;
  }
}

.centered {
  display: block;
  margin: 0 auto;
}

// Suppress :fpcus box-shadow
button.btn,
button.btn.btn-primary,
button.btn.btn-primary.btn-block,
button#faq-button,
a#gtm-nudge-btn,
select#state-select,
a.btn.btn-primary.nudge-btn {
  &:focus {
    box-shadow: none;
  }
  &:active {
    box-shadow: none;
    transform: scale(0.97);
  }
}

// hide arrows in number inputs
/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type='number'] {
  -moz-appearance: textfield;
}

/*** iPhone and iOS Form Input Zoom Fixes ***/
/* fixes for zooming in on focused inputs
/* https://www.warrenchandler.com/2019/04/02/stop-iphones-from-zooming-in-on-form-fields/
/* Fix Input Zoom on devices older than iPhone 5: */
@media screen and (device-aspect-ratio: 2/3) {
  select,
  textarea,
  input[type='text'],
  input[type='password'],
  input[type='datetime'],
  input[type='datetime-local'],
  input[type='date'],
  input[type='month'],
  input[type='time'],
  input[type='week'],
  input[type='number'],
  input[type='email'],
  input[type='url'] {
    font-size: 16px;
  }
}

/* Fix Input Zoom on iPhone 5, 5C, 5S, iPod Touch 5g */
@media screen and (device-aspect-ratio: 40/71) {
  select,
  textarea,
  input[type='text'],
  input[type='password'],
  input[type='datetime'],
  input[type='datetime-local'],
  input[type='date'],
  input[type='month'],
  input[type='time'],
  input[type='week'],
  input[type='number'],
  input[type='email'],
  input[type='url'] {
    font-size: 16px;
  }
}

/* Fix Input Zoom on iPhone 6, iPhone 6s, iPhone 7  */
@media screen and (device-aspect-ratio: 375/667) {
  select,
  textarea,
  input[type='text'],
  input[type='password'],
  input[type='datetime'],
  input[type='datetime-local'],
  input[type='date'],
  input[type='month'],
  input[type='time'],
  input[type='week'],
  input[type='number'],
  input[type='email'],
  input[type='tel'],
  input[type='url'] {
    font-size: 16px;
  }
}

/* Fix Input Zoom on iPhone 6 Plus, iPhone 6s Plus, iPhone 7 Plus, iPhone 8, iPhone X, XS, XS Max  */
@media screen and (device-aspect-ratio: 9/16) {
  select,
  textarea,
  input[type='text'],
  input[type='password'],
  input[type='datetime'],
  input[type='datetime-local'],
  input[type='date'],
  input[type='month'],
  input[type='time'],
  input[type='week'],
  input[type='number'],
  input[type='email'],
  input[type='tel'],
  input[type='url'] {
    font-size: 16px;
  }
}

.temp {
  b {
    color: $primary-red;
  }
  color: $primary-red;
}
