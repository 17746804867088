#crew-photo-prompt {
  padding: 5rem 1rem 6rem;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  // justify-content: space-between;
  h2 {
    font-size: 1.125rem;
    font-weight: 600;
    line-height: 1.5rem;
    text-align: center;
    margin-bottom: 2rem;
  }
  p.description {
    text-align: center;
  }
  .avatar-file-pick {
    position: relative;
    overflow: hidden;
    width: 40vw;
    height: 40vw;
    margin: 1rem auto;
    border-radius: 50%;
    border: 1px solid $primary-dark-navi;
    box-shadow: 0px 4px 16px #f1f5f8;
    display: flex;
    justify-content: center;
    align-items: center;
    input.file-picker {
      width: 80vw;
      height: 80vw;
      border-radius: 50%;
      z-index: 2;
    }
    .camera-icon {
      position: absolute;
      top: calc(50%-8px);
      right: calc(50%-8px);
      width: 16px;
      height: 16px;
    }
  }
  .crop-container {
    position: relative;
    height: 60vw;
    width: 60vw;
    margin: 1rem auto;
    z-index: 3;
  }
  img.profile {
    width: 40vw;
    border-radius: 50%;
    display: block;
    margin: 1rem auto;
  }
  #photo-bottom-section {
    button#skip {
      margin: 1rem auto 2rem;
      display: block;
      border: none;
      background: transparent;
      font-size: 0.875rem;
      color: $secondary-gray;
      font-weight: 400;
    }
    button.btn.btn-primary.btn-block {
      padding: 0.75rem;
    }
  }
}
