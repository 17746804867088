#vote-page {
  #non-partisan-copy {
    padding: 0.5rem 1rem;
    @include desktop {
      margin-top: 15vh;
    }
    h1 {
      font-size: 2rem;
      line-height: 2.5rem;
      font-weight: bold;
      text-align: left;
      color: $primary-vanilla;
      font-style: normal;
      margin: 0;
      width: 95%;
      &.blue {
        color: $primary-blue;
      }
      @include desktop {
        font-size: 2rem;
        line-height: 2.5rem;
        text-align: center;
        max-width: 30rem;
        margin: 0 auto;
      }
    }
    h3 {
      padding-top: 1.5rem;
      font-size: 1.125rem;
      font-weight: 600;
      line-height: 1.5rem;
      color: $primary-blue;
      padding-bottom: 0.25rem;
      @include desktop {
        padding-top: 2rem;
        text-align: center;
        max-width: 22rem;
        margin: 0 auto;
      }
    }
    p {
      color: $primary-vanilla;
      font-size: 1rem;
      line-height: 1.5rem;
      font-weight: normal;
      margin-bottom: 0.25rem;
      opacity: 0.7;
      @include desktop {
        text-align: center;
        padding-top: 0.25rem;
        // margin-bottom: 0;
      }
    }
  }
  main {
    padding-top: 0 !important;
    form {
      margin-top: 1rem;
      @include desktop {
        margin-top: 2rem;
      }
    }
  }
}
