@import './2016-election.scss';
@import './join-pages-header';
@import './progress-tabs';
@import './crew-deadline';
@import './user_form';
@import './user-photo-upload';

#code-confirmation {
  img {
    height: 16px;
    width: 16px;
    margin-bottom: 4px;
    margin-right: 0.5rem;
  }
  button.resend-button {
    border: none;
    background: transparent;
    color: $primary-red;
    font-weight: $semi-bold;
    padding: 1rem;
  }
  // Hide number picker
  input[type='number'] {
    -moz-appearance: textfield;
  }
  input[type='number']::-webkit-inner-spin-button,
  input[type='number']::-webkit-outer-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }
}
