#voter-info-page {
  #profile-top {
    background: $primary-dark-navi;
    padding: 1rem 0 2rem;
    position: relative;
    h2 {
      margin: 10vh 1rem 0 1rem;
      color: $white;
    }
    a {
      color: $primary-blue;
      margin-left: 1rem;
      font-size: 0.825rem;
      padding-bottom: 4rem;
    }
    img {
      margin: 1.75rem auto;
      margin-bottom: 0;
      height: 7rem;
      width: auto;
      display: block;
      border-radius: 50%;
    }
    .wrapper {
      margin-bottom: 2rem;
    }
  }

  .profile-details-container {
    position: relative;
    margin: 1.5rem 3.5vw 3vh 3.5vw;
    width: 93%;
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    align-content: space-between;
    background: white;
    border-radius: 4px;
    padding: 1.5vh;
    box-shadow: 0 4px 20px rgba(0, 0, 0, 0.25);

    img {
      height: 4.5rem;
      width: auto;
      display: block;
      border-radius: 50%;
    }

    .name-location-container {
      height: 4.5rem;
      display: flex;
      flex-direction: column;
      justify-content: space-around;
      h2 {
        margin: 0 0 0 1.2rem;
        color: $black;
      }

      span {
        margin: 0rem 0 0 1.2rem;
        font-size: 0.825rem;
      }
      &.not-monitoring {
        h2 {
          margin-top: 0.5rem;
        }
        span {
          margin-bottom: 0.5rem;
        }
      }
      .monitoring {
        display: flex;
        align-items: center;
        .blue-circle {
          height: 0.5rem;
          border-radius: 50%;
          width: 0.5rem;
          background: $primary-blue;
          margin-right: 0.25rem;
        }
        margin: 0.5rem 0 0 1.1rem;
        font-size: 0.75rem;
        font-weight: 500;
        color: $primary-blue;
      }
    }

    .todo {
      margin-left: auto;
      margin-bottom: 20px;
      height: 18px;
      width: 18px;
    }
  }

  #content {
    margin: 0 1rem 8rem 1rem;
    form {
      margin-bottom: 4vh;
    }
    h3 {
      margin-top: 1.5vh;
    }
    button {
      padding: 1rem;
      font-size: 0.825rem;
      width: 100%;
    }
    p {
      font-size: 0.825rem;
      margin: 1.5rem 0;
      color: $secondary-gray;
    }
    a {
      color: $primary-red;
    }

    #waiting-subtitle {
      padding: 1rem;
      margin: 2rem 0;
      background-color: $secondary-soft-blue;
      p {
        margin: 0;
        color: $secondary-gray;
      }
      button {
        margin-top: 2vh;
        border-radius: 4px;
        width: 55vw;
        padding: 0.7vh;
        font-weight: bold;
      }
    }

    .final-button {
      margin-bottom: 2rem !important;
    }

    .thin-button {
      margin-top: 1vh;
      margin-bottom: 2.5vh !important;
      border-radius: 4px;
      width: 60vw;
      padding: 0.7vh;
      font-weight: bold;
    }

    span {
      color: $primary-red;
    }

    .custom-control {
      margin: 2vh 0 2vh 0;

      .custom-control-label {
        padding: 0.4rem 0 0 0.75rem;
        min-width: 300px;
        font-size: 0.825rem;
      }
    }

    .custom-checkbox {
      width: 0;

      .custom-control-label::before {
        border-radius: 2px;
        width: 1.5rem;
        height: 1.5rem;
        border-color: $black;
      }

      .custom-control-label::after {
        border-radius: 2px;
        width: 1.5rem;
        height: 1.5rem;
        border-color: $black;
      }
    }
    .custom-control-input {
      opacity: 0;
      &:checked {
        background-color: $white;
      }
    }
  }

  #election-day-is-coming {
    position: relative;
    margin: 10vw 0 3vh 0;
    width: 100%;
    background: white;
    border-radius: 4px;
    padding: 2vh;
    box-shadow: 0 4px 20px rgba(0, 0, 0, 0.25);

    h3 {
      margin: 0.5vh auto;
      font-size: 1rem;
      color: $black;
    }

    h4 {
      margin: 0.5vh 0;
      font-size: 0.825rem;
      font-weight: 500;
      color: $secondary-gray;
      line-height: 20px;
    }

    button {
      margin-top: 2vh;
      border-radius: 4px;
      width: 40vw;
      padding: 0.7vh;
      font-size: 0.825rem;
    }
  }
}

#voter-info-pending {
  margin-top: 6rem;
}

#overlay-reg-status-notify {
  position: fixed;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
  z-index: 10;
  background: rgba(29, 8, 48, 0.9);
  color: $primary-vanilla;
  div.content {
    position: relative;
    height: 100%;
    padding: 1rem;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    .center-content {
      margin-top: 30vh;
    }
    img {
      height: 4rem;
      margin-bottom: 1rem;
    }
    h3 {
      width: 85%;
      font-size: 1.5rem;
      color: $primary-vanilla !important;
      line-height: 2rem;
      font-weight: 600;
    }
    button {
      position: fixed;
      width: 91vw;
      font-size: 0.875rem;
      font-weight: bold;
      padding: 12px;
    }
    button.check-it-out {
      bottom: 5rem;
      background: transparent;
      border: 1px solid $primary-vanilla;
    }
    button.bottom-aligned {
      bottom: 1rem;
    }
  }
}
