#election {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  > .content {
    flex-grow: 1;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
  }
  p {
    margin-top: 2rem;
  }
  .twenty-twenty-description {
    position: fixed;
    bottom: 6rem;
    margin: 0 5.25rem 0 4.5rem;
    font-size: $main-text;
    line-height: 1.25rem;
  }
  main {
    margin-top: 2rem;
  }
  .checkbox-container {
    margin-top: 1rem;
    display: flex;
    flex-direction: column;
    color: $secondary-gray;
  }

  // checkbox-item
  .checkbox-item {
    display: flex;
    border: 1px solid $primary-dark-navi;
    border-radius: 2px;
    margin-bottom: 12px;
    padding: remr(1.2);
    padding-left: 0.5rem;
  }

  // targets the label
  .custom-control-label {
    padding-left: 1rem;
    font-size: $mini-title;
    color: $primary-dark-navi;
  }

  // targets the checkbox
  .custom-control-label::before,
  .custom-control-label::after {
    top: 50%;
    transform: translateY(-50%);
    -ms-transform: translateY(-50%);
    -moz-transform: translateY(-50%);
    -webkit-transform: translateY(-50%);
    -o-transform: translateY(-50%);
    width: 1.33rem;
    height: 1.33rem;
    margin-left: 10px;
  }
  #login-button {
    font-size: 0.875rem;
    padding: 0.75rem;
  }
}

// outside of id scope to target global bootstrap style
.custom-checkbox .custom-control-label:before {
  background-color: $primary-vanilla;
  border: 1px solid $primary-dark-navi;
  border-radius: 2px;
}

.custom-checkbox .custom-control-input:checked {
  background: $primary-red;
  border-radius: 2px;
}
