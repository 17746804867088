#early-voting {
  margin: 1.5rem 1rem 8rem 1rem;
  p {
    color: $black;
  }
  header {
    text-align: center;
    margin-bottom: 2vh;
    h2 {
      font-size: $h3-semibold;
      text-align: center;
      line-height: 24px;
      margin-bottom: 0;
    }
    a {
      color: $primary-red;
      text-align: center;
      font-size: 0.875rem;
    }
  }

  #options-intro {
    text-align: center;
    margin: 1.5rem 0rem;
  }

  div.donation-card {
    padding: 1.25rem 1.25rem;
    margin-bottom: 2rem;
    border-radius: 4px;
    border: solid 2px $secondary-gray;
    h2 {
      text-align: center;
      margin-bottom: 1rem;
    }
    a {
      &:hover {
        text-decoration: none;
      }
    }
    button {
      font-size: 0.875rem;
      font-weight: 700;
      margin: 0 auto 0;
      padding: 0.75rem;
      display: block;
      border-radius: 4px;
      margin-top: 1rem;
      background-color: $primary-blue;
      border-color: $primary-blue;
      &:hover {
        border: 1px solid transparent;
      }
    }
  }

  div.powered-by {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-bottom: 2rem;
    b {
      font-weight: bold;
      font-style: italic;
      font-size: 0.75rem;
      margin-right: 0.5rem;
    }
    img {
      height: 20px;
    }
  }
}
